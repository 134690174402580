<template>
    <div style="width: 100%;">
        <JSCharting
            :options="stackedChartOption"
            class="columnChart"
            :mutable="false"
        />
    </div>
</template>

<script>
import JSCharting from "jscharting-vue";
import {JSC} from "jscharting-vue";
import {Api} from "@/api/index";
import moment from "moment-timezone";

moment.tz.setDefault("Asia/Ho_Chi_Minh");
moment.locale("vi");

const getStatisticTimeInZone = Api.get("getStatisticTimeInZone");

export default {
    name: "StackedBarChartInteractionRate",
    components: {
        JSCharting
    },
    props: {
        stackedBarChartQuery: Object,
    },
    mounted() {
        this.lang = localStorage.lang === "vn" ? "vi" : "en"
    },
    data() {
        return {
            lang: "vn",
            stackedChartOption: {
                debug: false,
                defaultSeries: {
                    events: {
                        legendEntryClick: function (e) {
                            return false;
                        }
                    }
                },
                type: 'horizontalColumnSolid',
                palette: ["#064B6C", "#5C8EBF", "#7BBEFF4D"],
                legend_position: 'bottom',
                legend: {
                    opacity: 0,
                    template: "%icon %name",
                    reversed: true
                },
                yAxis: {
                    scale_type: 'stackedFull',
                    defaultTick_label_text: '%value%',
                    formatString: 'n0',
                    visible: false
                },
                xAxis: {
                    categories: ["Q1", "Q2", "Q3"],
                    label: {}
                },
                defaultPoint_tooltip: '%seriesname<br><b>%value (%percentOfGroup%)</b>',
                defaultPoint: {
                    outline_width: 0,
                    label: {
                        text: '%percentOfGroup%',
                        color: "#fff",
                        offset: '0,0',
                        autoHide: false
                    }
                },
                series: [
                    {
                        name: localStorage.lang === "vn" ? "Lớn hơn 60 giây" : "Greater than 60 seconds",
                        points: [1, 1, 1]
                    },
                    {
                        name: localStorage.lang === "vn" ? "Từ 20 đến 60 giây" : "From 20 seconds to 60 seconds",
                        points: [1, 1, 1]
                    },

                    {
                        name: localStorage.lang === "vn" ? "Từ 5 đến 20 giây" : "From 5 seconds to 20 seconds",
                        id: 's1',
                        points: [1, 1, 1]
                    }
                ]
            }
        }
    },
    methods: {
        getStatisticTimeInZone(groupID, dateStart, dateEnd) {
            getStatisticTimeInZone.get(groupID, dateStart, dateEnd).then(response => {
                this.processTimeInZone(response.data["data"])
            })
        },
        processTimeInZone(listTimeInZoneData) {
            let listFrom05To20 = []
            let listFrom20To60 = []
            let listGreaterThan60 = []
            let listCategory = []

            for (let zoneData of listTimeInZoneData) {
                listCategory.push(zoneData["name"])
                listFrom05To20.push(zoneData["time"][0])
                listFrom20To60.push(zoneData["time"][1])
                listGreaterThan60.push(zoneData["time"][2])
            }
            if (listCategory.length === 0) {
                listCategory = ["Q1", "Q2", "Q3"]
            }

            const defaultPoint = Array(listCategory.length).fill(1)

            let series = []
            if (listGreaterThan60.reduce((a, b) => a + b, 0) !== 0 || listGreaterThan60.length > 0) {
                series.push({
                    name: localStorage.lang === "vn" ? "Lớn hơn 60 giây" : "Greater than 60 seconds",
                    points: listGreaterThan60
                })
            } else {

                series.push({
                    name: localStorage.lang === "vn" ? "Lớn hơn 60 giây" : "Greater than 60 seconds",
                    points: defaultPoint
                })
            }
            if (listFrom20To60.reduce((a, b) => a + b, 0) !== 0 || listFrom20To60.length > 0) {
                series.push({
                    name: localStorage.lang === "vn" ? "Từ 20 đến 60 giây" : "From 20 seconds to 60 seconds",
                    points: listFrom20To60
                })
            } else {
                series.push({
                    name: localStorage.lang === "vn" ? "Từ 20 đến 60 giây" : "From 20 seconds to 60 seconds",
                    points: defaultPoint
                })
            }
            if (listFrom05To20.reduce((a, b) => a + b, 0) !== 0 || listFrom05To20.length > 0) {
                series.push({
                    name: localStorage.lang === "vn" ? "Từ 5 đến 20 giây" : "From 5 seconds to 20 seconds",
                    points: listFrom05To20
                })
            } else {
                series.push({
                    name: localStorage.lang === "vn" ? "Từ 5 đến 20 giây" : "From 5 seconds to 20 seconds",
                    points: defaultPoint
                })
            }

            this.stackedChartOption = {
                debug: false,
                defaultSeries: {
                    events: {
                        legendEntryClick: function (e) {
                            return false;
                        }
                    }
                },
                type: 'horizontalColumnSolid',
                palette: ["#064B6C", "#5C8EBF", "#7BBEFF4D"],
                legend_position: 'bottom',
                legend: {
                    opacity: 0,
                    template: "%icon %name",
                    reversed: true
                },
                yAxis: {
                    scale_type: 'stackedFull',
                    defaultTick_label_text: '%value%',
                    formatString: 'n0',
                    visible: false
                },
                xAxis: {
                    categories: listCategory,
                    defaultTick: {
                        label_text: '<span style="text-align:right !important">%value</span>'
                    },
                },
                defaultPoint_tooltip: '%seriesname<br><b>%value (%percentOfGroup%)</b>',
                defaultPoint: {
                    outline_width: 0,
                    label: {
                        text: '<b>%percentOfGroup%</b>',
                        color: "#000",
                        offset: '0,0',
                        autoHide: true
                    }
                },
                series: series
            }
        }
    },
    watch: {
        stackedBarChartQuery(stackedBarChartQuery) {
            this.getStatisticTimeInZone(stackedBarChartQuery.groupID, stackedBarChartQuery.dateRange["dateStart"], stackedBarChartQuery.dateRange["dateEnd"])
        },
    }
}
</script>

<style scoped lang="scss">

</style>