<template>
    <div class="TreeMap " :class="{ 'mt-40': isRetail, TreeMap: true }">
        <span v-if="isRetail == false" class="titleTree mt-12">
            {{ $t('reportShopingMall.gatePerformance').toUpperCase() }}
        </span>
        <div class="HeaderTreeMap " v-if="isRetail == true">
            <div class="TitleTreeMap">
                {{ $t('Report.Top6Store') }}
            </div>
            <div class="Btn-TreeMap" @click="chooseTreeMapOption()" v-click-outside="handleClickOutSideTreeMap">
                {{ treeMapOption }}
                <span class="material-icons">arrow_drop_down</span>
                <div ref="wrapTreeMapOption" class="wrapOption">
                    <div class="Option" @click="changeOptionTreeMap('Passby')">
                        {{ $t('dashboard.Passby') }}
                    </div>
                    <div class="Option" @click="changeOptionTreeMap('Footfall')">
                        {{ $t('dashboard.Footfall') }}
                    </div>
                    <div class="Option" @click="changeOptionTreeMap('Transactions')">
                        {{ $t('dashboard.Transactions') }}
                    </div>
                    <div class="Option" @click="changeOptionTreeMap('Dwelltime')">
                        {{ $t('dashboard.Dwelltime') }}
                    </div>
                    <div class="Option" @click="changeOptionTreeMap('TurnInRate')">
                        {{ $t('dashboard.TurnInRate') }}
                    </div>
                    <div class="Option" @click="changeOptionTreeMap('SaleConversionRate')">
                        {{ $t('dashboard.SaleConversionRate') }}
                    </div>
                </div>
            </div>
        </div>
        <div id="chart" v-if="isEmptyTreeMap == false">
            <apexchart type="treemap" :height="height" :options="chartOptions" :series="treeMapData"></apexchart>
        </div>
        <div v-if="isEmptyTreeMap == true">
            {{ $t('dashboard.NoData') }}
        </div>
    </div>
</template>

<script>
import { Api } from '@/api/index';
const topEfficientStore = Api.get('topEfficientStore');
const gatePerformanceReportShoppingMall = Api.get('gatePerformanceReportShoppingMall');

export default {
    mounted() {
        this.dateStart = this.dateRange['dateStart'];
        this.dateEnd = this.dateRange['dateEnd'];
        if (this.isRetail) {
            this.getTreeMapDataFromAPI();
        } else {
            this.getGatePerformanceAPI();
        }
    },
    methods: {
        chooseTreeMapOption() {
            this.$refs['wrapTreeMapOption'].style.position = 'absolute';
            this.$refs['wrapTreeMapOption'].style.display = 'block';
            this.$refs['wrapTreeMapOption'].style.visibility = 'visible';
        },
        handleClickOutSideTreeMap() {
            this.$refs['wrapTreeMapOption'].style.display = 'none';
            this.$refs['wrapTreeMapOption'].style.visibility = 'hidden';
        },
        changeOptionTreeMap(option) {
            let self = this;
            setTimeout(() => {
                self.handleClickOutSideTreeMap();
            }, 100);
            this.treeMapOption = this.$t('dashboard.' + option);
            if (this.treeMapOption == this.$t('dashboard.Passby')) {
                this.option = 'way';
            } else if (this.treeMapOption == this.$t('dashboard.Footfall')) {
                this.option = 'gate';
            } else if (this.treeMapOption == this.$t('dashboard.TurnInRate')) {
                this.option = 'rateGate';
            } else if (this.treeMapOption == this.$t('dashboard.SaleConversionRate')) {
                this.option = 'rateShop';
            } else if (this.treeMapOption == this.$t('dashboard.Dwelltime')) {
                this.option = 'timeAverage';
            } else {
                this.option = 'shop';
            }
            this.getTreeMapDataFromAPI();
        },
        getTreeMapDataFromAPI() {
            let listData = [];
            let data = {};
            topEfficientStore.get(this.dateStart, this.dateEnd, this.compareType, this.option).then(response => {
                this.isEmptyTreeMap = true;
                for (let index in response.data.data) {
                    if (response.data.data[index].value != 0) {
                        this.isEmptyTreeMap = false;
                    }
                    let shop = {
                        x: response.data.data[index].name,
                        y: response.data.data[index].value,
                    };
                    listData.push(shop);
                }

                data.data = listData;
                this.treeMapData = [data];
                let self = this;
                this.chartOptions = {
                    legend: {
                        show: false,
                    },
                    chart: {
                        height: this.height,
                        type: 'treemap',
                    },
                    tooltip: {
                        style: {
                            fontSize: '14px',
                            fontFamily: 'Roboto',
                        },
                        y: {
                            formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {
                                let unit = '';
                                if (self.treeMapOption == self.$t('dashboard.TurnInRate')) {
                                    unit = '%';
                                } else if (self.treeMapOption == self.$t('dashboard.SaleConversionRate')) {
                                    unit = '%';
                                } else if (self.treeMapOption == self.$t('dashboard.Dwelltime')) {
                                    unit = 's';
                                }
                                return value.toLocaleString('en-US') + unit;
                            },
                        },
                    },
                    dataLabels: {
                        enabled: true,
                        style: {
                            fontSize: '20px',
                            fontFamily: 'Roboto',
                            fontWeight: '500',
                        },
                        formatter: function(text, op) {
                            let unit = '';
                            if (self.treeMapOption == self.$t('dashboard.TurnInRate')) {
                                unit = '%';
                            } else if (self.treeMapOption == self.$t('dashboard.SaleConversionRate')) {
                                unit = '%';
                            } else if (self.treeMapOption == self.$t('dashboard.Dwelltime')) {
                                unit = 's';
                            }
                            if (op.value == 0) {
                                return '';
                            }
                            return [text, op.value.toLocaleString('en-US') + unit];
                        },
                        offsetY: -4,
                    },
                    colors: ['#064B6C', '#43688C', '#5C8EBF', '#68A1D9', '#7BBEFF', '#A1D1FF'],
                    plotOptions: {
                        treemap: {
                            distributed: true,
                            enableShades: false,
                        },
                    },
                };
            });
        },
        getGatePerformanceAPI() {
            gatePerformanceReportShoppingMall.get(this.dateStart, this.dateEnd).then(response => {
                const gateDataResponse = response.data.data;
                let listGateData = [];
                this.isEmptyTreeMap = true;
                for (const gate of gateDataResponse) {
                    if (gate.total !== 0) {
                        this.isEmptyTreeMap = false;
                    }

                    listGateData.push({
                        x: gate.name,
                        y: gate.total,
                    });
                }

                this.treeMapData = [{ data: listGateData }];
                let self = this;
                this.chartOptions = {
                    legend: {
                        show: false,
                    },
                    chart: {
                        height: this.height,
                        type: 'treemap',
                    },
                    tooltip: {
                        style: {
                            fontSize: '14px',
                            fontFamily: 'Roboto',
                        },
                        y: {
                            formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {
                                let unit = '';
                                if (self.treeMapOption == self.$t('dashboard.TurnInRate')) {
                                    unit = '%';
                                } else if (self.treeMapOption == self.$t('dashboard.SaleConversionRate')) {
                                    unit = '%';
                                } else if (self.treeMapOption == self.$t('dashboard.Dwelltime')) {
                                    unit = 's';
                                }
                                return value.toLocaleString('en-US') + unit;
                            },
                        },
                    },
                    dataLabels: {
                        enabled: true,
                        style: {
                            fontSize: '20px',
                            fontFamily: 'Roboto',
                            fontWeight: '500',
                        },
                        formatter: function(text, op) {
                            let unit = '';
                            if (self.treeMapOption == self.$t('dashboard.TurnInRate')) {
                                unit = '%';
                            } else if (self.treeMapOption == self.$t('dashboard.SaleConversionRate')) {
                                unit = '%';
                            } else if (self.treeMapOption == self.$t('dashboard.Dwelltime')) {
                                unit = 's';
                            }
                            if (op.value == 0) {
                                return '';
                            }
                            return [text, op.value.toLocaleString('en-US') + unit];
                        },
                        offsetY: -4,
                    },
                    colors: ['#064B6C', '#43688C', '#5C8EBF', '#68A1D9', '#7BBEFF', '#A1D1FF'],
                    plotOptions: {
                        treemap: {
                            distributed: true,
                            enableShades: false,
                        },
                    },
                };
            });
        },
    },
    props: {
        dateRange: {
            type: Object,
        },
        compareType: {
            default: 'normal',
            type: String,
        },
        isRetail: {
            type: Boolean,
            default: false,
        },
        height: {
            type: Number,
            default: 400,
        },
    },
    watch: {
        dateRange: {
            handler: function(val) {
                this.dateStart = val['dateStart'];
                this.dateEnd = val['dateEnd'];
                if (this.isRetail) {
                    this.getTreeMapDataFromAPI();
                } else {
                    this.getGatePerformanceAPI();
                }
            },
            deep: true,
        },
        compareType(val) {
            this.getTreeMapDataFromAPI();
        },
        height(val) {
            this.chartOptions = {
                legend: {
                    show: false,
                },
                chart: {
                    height: val,
                    type: 'treemap',
                },
                tooltip: {
                    style: {
                        fontSize: '14px',
                        fontFamily: 'Roboto',
                    },
                },
                dataLabels: {
                    enabled: false,
                    style: {
                        fontSize: '20px',
                        fontFamily: 'Roboto',
                        fontWeight: '500',
                    },
                    formatter: function(text, op) {
                        if (op.value == 0) {
                            return '';
                        }
                        return text + '<b>' + op.value.toLocaleString('en-US') + '</b>';
                    },
                    offsetY: -4,
                },
                colors: ['#064B6C', '#43688C', '#5C8EBF', '#68A1D9', '#7BBEFF', '#A1D1FF'],
                plotOptions: {
                    treemap: {
                        distributed: true,
                        enableShades: false,
                    },
                },
            };
        },
    },
    data() {
        let self = this;
        return {
            dateStart: 0,
            dateEnd: 0,
            isEmptyTreeMap: true,
            option: 'gate',
            treeMapData: [
                {
                    data: [
                        {
                            x: 'Shop1',
                            y: 3,
                        },
                        {
                            x: 'Shop2',
                            y: 5,
                        },
                    ],
                },
            ],
            treeMapOption: self.$t('dashboard.Footfall'),
            chartOptions: {
                legend: {
                    show: false,
                },
                chart: {
                    height: 350,
                    type: 'treemap',
                },
                tooltip: {
                    style: {
                        fontSize: '14px',
                        fontFamily: 'Roboto',
                    },
                    y: {
                        formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {
                            return value.toLocaleString('en-US');
                        },
                    },
                },
                dataLabels: {
                    enabled: true,
                    style: {
                        fontSize: '20px',
                        fontFamily: 'Roboto',
                    },
                    formatter: function(text, op) {
                        if (op.value == 0) {
                            return '';
                        }
                        return [text, op.value.toLocaleString('en-US')];
                    },
                    offsetY: -4,
                },
                colors: ['#064B6C', '#43688C', '#5C8EBF', '#68A1D9', '#7BBEFF', '#A1D1FF'],
                plotOptions: {
                    treemap: {
                        distributed: true,
                        enableShades: false,
                    },
                },
            },
        };
    },
};
</script>

<style lang="scss">
.TreeMap {
    background: #ffffff;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
    height: 100%;
    padding: 24px;

    .HeaderTreeMap {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        .TitleTreeMap {
            font-family: 'Roboto';
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
            color: #1f1f1f;
        }

        .Btn-TreeMap {
            padding: 8px 8px;
            border: 1px solid #8c8c8c;
            border-radius: 8px;
            display: flex;
            align-items: center;
            font-weight: 500;
            position: relative;
            margin-top: -8px;
            margin-right: 5px;
            cursor: pointer;

            .material-icons {
                color: #8c8c8c;
            }

            .StylesBTN {
                font-family: 'Roboto';
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                color: #434343;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 96px;
            }

            .wrapOption {
                overflow: auto;
                background: #ffffff;
                box-sizing: border-box;
                box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.05);
                border-radius: 8px;
                position: absolute;
                width: 250px !important;
                top: 43px;
                z-index: 9999;
                // visibility: hidden;
                max-height: 312px;
                overflow: auto;

                .Option {
                    padding: 16px;
                    font-family: 'Roboto';
                    font-size: 14px;
                    line-height: 150%;
                    color: #333333;
                    min-width: 180px;
                    font-weight: 400;
                    cursor: pointer;

                    &:hover {
                        background-color: #f6f9ff;
                    }
                }
            }
        }
    }
}

.titleTree {
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #1f1f1f;
}
</style>
