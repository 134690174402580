<template>
    <div class="wrapChartFunnel">
        <fusioncharts
            :type="type"
            :width="width"
            :height="height"
            :fill="none"
            :dataformat="dataFormat"
            :dataSource="dataSource"
        >
        </fusioncharts>
    </div>
</template>
<script>
export default {
    name: "funnel-chart", 
    watch: {
        chartData(val) {
            this.$set(this.dataSource, "data", val)
        },
        chartLeftMargin(val) {
            this.$set(this.dataSource.chart, "chartLeftMargin", val)
        }
    },
    props: {
        chartData: {
            type: Array,
        },
        chartLeftMargin: {
            type: String,
        }
    },
    mounted() {
        this.$set(this.dataSource, "data", this.chartData)
    },
    data() {
        return {
            type: "funnel",
            width: "124%",
            height: "408",
            
            dataFormat: "json",
            dataSource: {
                chart: {
                    showTooltip: 0,
                    baseFont: "Roboto",
                    baseFontSize: 14,
                    labelFontBold: 1,
                    //Filled Funnel Slices
                    isHollow: "0",
                    isSliced: "0",
                    labelDistance: "26",
                    plotTooltext: "Success : $percentOfPrevValue",
                    theme: "fusion",
                    chartLeftMargin: "-50",
                    chartTopMargin: "-30",
                    chartBottomMargin: "-70",
                    palettecolors: "001E6C,035397,E8630A,E8630A",
                    bgColor: "#F8FBFF",
                    labelFontColor : "000000",
                    labelFontSize: "16",
                },
                data: [
                    {
                        label: "",
                        value: "1460000"
                    },
                    {
                        label: "Người đi qua (1890)",
                        value: "53000"
                    },
                    {
                        label: "Khách đi vào (890)",
                        value: "100000"
                    },
                    {
                        label: "Khách mua hàng (90)",
                        value: "210000"
                    }
                ]
            }
        };
    }
};
</script>
<style lang="scss" scoped>
.wrapChartFunnel {
    position: absolute;
    top: -20px;
    left: -115px;
}
</style>