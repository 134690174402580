<template>
    <div ref="columnChart" style="width: 105%;">
        <JSCharting :mutable="typeReport == 'retail' ? false : true" :options="chartOptions" :class="typeReport == 'retail' ? 'columnChartRetail' : 'columnChartReport'"></JSCharting>
    </div>
</template>

<script>
import JSCharting from "jscharting-vue";
import { JSC } from "jscharting-vue";
import * as moment from "moment-timezone";
import "moment/locale/vi";

export default {
    props: {
        maxNumber: Number,
        rawData: Array,
        listDateRange: Array,
        listColorToUse: Array,
        typeReport: {
            type: String,
            default: "shoppingMall",
        },
        textLabel: String
    },
    name: "LineChartForDashboardShopingmall",
    components: {
        JSCharting
    },
    watch: {
        rawData(val) {
            var scale = {range: [0, 10]}
            if (this.typeReport == "retail") {
                this.$refs.columnChart.style = "100%"
                if (this.maxNumber != 0) {
                    scale = {range: [0, this.maxNumber+this.maxNumber/4]}
                }
                
            } else {
                if (this.maxNumber != 0) {
                    scale = {range: [0, this.maxNumber], interval: parseInt(this.maxNumber/5) }
                }
                this.textLabel = ""
            }

            var self = this
            
            self.chartOptions = {
                debug: false,
                type: "line",
                legend_visible: false,
                defaultAxis: {
                    line_width: 0,
                    alternateGridFill: "none",
                    defaultTick_gridLine_color: "#e5e5e5"
                },
                yAxis: { 
                    scale: scale, 
                },
                xAxis: {
                    crosshair_enabled: true,
                    scale_type: "time",
                    defaultTick: {
                        label_text: self.labelFormatter
                    },
                },
                defaultTooltip: {
                    padding: 15,
                    label_text: self.tooltipFormatter
                },
                toolbar_visible: false,
                defaultSeries: {
                    color: self.dataColor
                },
                series: val
            };
        },
    },
    data() {
        var self = this;
        return {
            dataColor: "#8698fa",
            dateArray: [
                "11/01",
                "12/01",
                "13/01",
                "14/01",
                "15/01",
                "16/01",
                "17/01",
                "18/01",
                "19/01",
                "20/01",
                "21/01",
                "22/01",
                "23/01",
                "24/01",
                "25/01",
                "26/01",
                "27/01",
            ],
            hourArray: [
                "7h - 8h",
                "8h - 9h",
                "9h - 10h",
                "10h - 11h",
                "11h - 12h",
                "12h - 13h",
                "13h - 14h",
                "14h - 15h",
                "15h - 16h",
                "16h - 17h",
                "17h - 18h",
                "18h - 19h",
                "19h - 20h",
                "20h - 21h",
                "21h - 22h",
                "22h - 23h",
                "23h - 24h",
            ],
            chartOptions: {
                debug: false,
                type: "line",
                legend_visible: false,
                margin: { bottom: 5, right: 20 },
                defaultAxis: {
                    line_width: 0,
                    alternateGridFill: "none",
                    defaultTick_gridLine_color: "#e5e5e5"
                },
                xAxis: {
                    crosshair_enabled: true,
                    scale_type: "time",
                    defaultTick: {
                        label_text: self.labelFormatter
                    },
                    scale: {
                        minorInterval: {
                            unit: "day",
                            multiplier: 1
                        }
                    },
                    minorInterval: {
                        unit: "day",
                        multiplier: 1
                    }
                },
                defaultTooltip: {
                    padding: 15,
                    label_text: self.tooltipFormatter
                },
                toolbar_visible: false,
                defaultSeries: {
                    color: self.dataColor
                },
                series: [
                    {
                        name: "previous",
                        defaultPoint: {
                            marker: {
                                outline: {
                                    width: 5,
                                    color: "white"
                                },
                                fill: "#004961",
                                type: "circle",
                                visible: true,
                                size: 12
                            }
                        },
                        line: {
                            color: "#004961",

                            // dashStyle: "dash",
                            width: 1
                        },
                        points: [
                        ]
                    },
                    {
                        name: "now",
                        defaultPoint: {
                            marker: {
                                outline: {
                                    width: 5,
                                    color: "white"
                                },
                                fill: "#D81F41",
                                type: "circle",
                                visible: true,
                                size: 12
                            }
                        },
                        line: {
                            color: "#D81F41"
                        },
                        points: [
                        ]
                    }
                ]
            },
            yesterday: "",
            todayString: ""
        };
    },
    methods: {
        convertTime(value) {
            let hour = 0
            let redundantHour = 0
            let minute = 0
            let second = 0
            let finalValue = ""

            let hourSring = "h "
            let minuteSring = "m "
            let secondSring = "s "

            if (localStorage.lang == "vn") {
                hourSring = " giờ "
                minuteSring = " phút "
                secondSring = " giây "
            }
            if (value >= 3600) {
                hour = Math.floor(value / 3600)
                redundantHour = value % 3600
                minute = Math.floor(redundantHour / 60)
                second = Math.floor(redundantHour % 60)
                finalValue = hour + hourSring + minute + minuteSring + second + secondSring 
            } else if (value < 3600 && value >= 60) {
                minute = Math.floor(value / 60)
                second = Math.floor(value % 60)
                finalValue = minute + minuteSring + second + secondSring
            }
            return finalValue
        },
        labelFormatter(timestampFordate) {
            var hourRange = this.dateArray.indexOf(moment.unix(timestampFordate/1000).format("DD/MM"))
            // if (moment.unix(timestampFordate / 1000).hour() === 0) {
            //     return this.hourArray[hourRange];
            // }
            return this.hourArray[hourRange];
            return "";
        },
        //ve tooltip o day
        tooltipFormatter(pts) {
            var self = this
            pts = JSC.sortBy(pts, "series._i");
            var timestamp = moment(pts[0].x).format("X")
            var hour = this.dateArray.indexOf(moment.unix(timestamp).format("DD/MM"))

            var tooltipContent = "";
            var dateDay = '<span style="align:left;color:#1F1F1F;font-family: Roboto;font-style: normal;font-weight: 500;font-size: 16px;line-height: 24px;">' + self.hourArray[hour]
            for (var i = 0; i < this.rawData.length; i++) {
                let value = pts[i].y.toLocaleString("en-US")
                if (this.textLabel == "s") {
                    value = this.convertTime(pts[i].y)
                }
                var line =
                    "</span><br/><br/>" +
                    '<span style="vertical-align: middle;font-size: 24px;color: ' + this.listColorToUse[i] + ';margin-bottom:16px; display:block;font-weight: 900"> ― <span style="vertical-align: middle;font-family: Roboto;font-style: normal;font-size: 18px;line-height: 20px;color: #1F1F1F;font-weight: 400;">' +
                    this.rawData[i].name +
                    ": " +
                    value +
                    "</span> </span><br/>";
                tooltipContent = tooltipContent + line;
            }
            return (
                dateDay +
                tooltipContent
            );
        }
    }
};
</script>

<style lang="scss" scoped>
.columnChartReport {
    height: 370px;
}
.columnChartRetail {
    height: 390px;
}
</style>
