<template>
    <div ref="columnChart" style="width: 105%;">
        <JSCharting
            :mutable="typeReport == 'retail' ? false : true"
            :options="chartOptions"
            :class="typeReport == 'retail' ? 'columnChartRetail' : 'columnChartReport'"
        ></JSCharting>
    </div>
</template>

<script>
import JSCharting from 'jscharting-vue';
import { JSC } from 'jscharting-vue';
import * as moment from 'moment-timezone';
import 'moment/locale/vi';

export default {
    name: 'LineChartForDashboardShopingmall',
    props: {
        maxNumber: Number,
        textLabel: {
            type: String,
            default: ' ',
        },
        rawData: Array,
        year: Number,
        listDateRange: Array,
        listColorToUse: Array,
        typeReport: {
            type: String,
            default: 'shopingmall',
        },
    },
    mounted() {
        if (localStorage.lang == 'en') {
            this.listDayInWeek = ['MO ', 'TU ', 'WE ', 'TH ', 'FR ', 'SA ', 'SU '];
        }
    },
    watch: {
        rawData(val) {
            var textLabel = '';
            var scale = { range: [0, 10] };
            if (this.typeReport == 'retail') {
                this.$refs.columnChart.style = '100%';
                scale = { range: [0, this.maxNumber + this.maxNumber / 4] };
            } else {
                this.textLabel = '';
                if (this.maxNumber != 0) {
                    scale = { range: [0, this.maxNumber], interval: parseInt(this.maxNumber / 5) };
                }
            }
            if (this.textLabel == undefined) {
                this.textLabel = '';
            }
            var self = this;
            self.chartOptions = {
                debug: false,
                type: 'line',
                legend_visible: false,
                defaultAxis: {
                    line_width: 0,
                    alternateGridFill: 'none',
                    defaultTick_gridLine_color: '#e5e5e5',
                },
                yAxis: {
                    scale: scale,
                    defaultTick_label_text: '%value' + this.textLabel,
                    defaultTick: {
                        label: {
                            style: {
                                fontSize: 14,
                                fontFamily: 'Roboto',
                                color: '#5C6066',
                                align: 'left',
                            },
                        },
                        gridLine: {
                            width: 1,
                            color: '#D9D9D9',
                        },
                    },
                },
                xAxis: {
                    crosshair_enabled: true,
                    scale_type: 'time',
                    defaultTick: {
                        label_text: self.labelFormatter,
                    },
                    scale: {
                        minorInterval: {
                            unit: 'day',
                            multiplier: 1,
                        },
                    },
                },
                defaultTooltip: {
                    padding: 15,
                    label_text: self.tooltipFormatter,
                },
                toolbar_visible: false,
                defaultSeries: {
                    color: self.dataColor,
                },
                series: val,
            };
        },
    },
    components: {
        JSCharting,
    },
    data() {
        var self = this;
        return {
            listDayInWeek: ['T2 ', 'T3 ', 'T4 ', 'T5 ', 'T6 ', 'T7 ', 'CN '],
            listDayInWeekFull: [
                self.$t('dashboard.Monday'),
                self.$t('dashboard.Tuesday'),
                self.$t('dashboard.Wednesday'),
                self.$t('dashboard.Thursday'),
                self.$t('dashboard.Friday'),
                self.$t('dashboard.Saturday'),
                self.$t('dashboard.Sunday'),
            ],
            month: 1,
            dataColor: '#8698fa',
            chartOptions: {
                debug: false,
                type: 'line',
                legend_visible: false,
                margin: { bottom: 5, right: 20 },
                defaultAxis: {
                    line_width: 0,
                    alternateGridFill: 'none',
                    defaultTick_gridLine_color: '#e5e5e5',
                },
                xAxis: {
                    crosshair_enabled: true,
                    scale_type: 'time',
                    defaultTick: {
                        label_text: self.labelFormatter,
                    },
                },
                defaultTooltip: {
                    padding: 15,
                    label_text: self.tooltipFormatter,
                },
                toolbar_visible: false,
                defaultSeries: {
                    color: self.dataColor,
                },
                series: [
                    {
                        name: 'previous',
                        defaultPoint: {
                            marker: {
                                outline: {
                                    width: 1,
                                    color: 'white',
                                },
                                fill: '#004961',
                                type: 'circle',
                                visible: true,
                                size: 12,
                            },
                        },
                        line: {
                            color: '#004961',

                            // dashStyle: "dash",
                            width: 2,
                        },
                        points: [],
                    },
                ],
            },
            listColor: [
                '#004961',
                '#D81F41',
                '#68768A',
                '#00CC6A',
                '#004961',
                '#FFB900',
                '#0063B1',
                '#B146C2',
                '#0099BC',
            ],
        };
    },
    methods: {
        convertTime(value) {
            let hour = 0;
            let redundantHour = 0;
            let minute = 0;
            let second = 0;
            let finalValue = '';

            let hourSring = 'h ';
            let minuteSring = 'm ';
            let secondSring = 's ';

            if (localStorage.lang == 'vn') {
                hourSring = ' giờ ';
                minuteSring = ' phút ';
                secondSring = ' giây ';
            }
            if (value >= 3600) {
                hour = Math.floor(value / 3600);
                redundantHour = value % 3600;
                minute = Math.floor(redundantHour / 60);
                second = Math.floor(redundantHour % 60);
                finalValue = hour + hourSring + minute + minuteSring + second + secondSring;
            } else if (value < 3600 && value >= 60) {
                minute = Math.floor(value / 60);
                second = Math.floor(value % 60);
                finalValue = minute + minuteSring + second + secondSring;
            }
            if (value < 60) {
                finalValue = value + secondSring;
            }
            return finalValue;
        },
        labelFormatter(timestampFordate) {
            if (moment.unix(timestampFordate / 1000).format('HH:mm:ss') != '00:00:00') {
                return '';
            }
            var weekDay = '';
            if (moment.unix(timestampFordate / 1000).day() - 1 == -1) {
                weekDay = this.listDayInWeek[this.listDayInWeek.length - 1];
            } else {
                weekDay = this.listDayInWeek[moment.unix(timestampFordate / 1000).day() - 1];
            }
            var hour = moment.unix(timestampFordate / 1000).format('DD/MM');
            return weekDay + hour;
        },
        tooltipFormatter(pts) {
            pts = JSC.sortBy(pts, 'series._i');
            var weekDay = '';
            if (moment(pts[0].x).day() - 1 == -1) {
                weekDay = this.listDayInWeekFull[this.listDayInWeekFull.length - 1];
            } else {
                weekDay = this.listDayInWeekFull[moment(pts[0].x).day() - 1];
            }
            var dateDay = moment(pts[0].x).format('DD/MM') + ' ' + weekDay;
            var tooltipContent = '';
            for (var i = 0; i < this.rawData.length; i++) {
                var line = '';
                for (var z = 0; z < pts.length; z++) {
                    if (this.rawData[i].name == pts[z].series.name) {
                        let value = pts[z].y.toLocaleString('en-US');
                        if (this.textLabel == 's') {
                            value = this.convertTime(pts[z].y);
                        }
                        line =
                            '<span style="vertical-align: middle;font-size: 24px;color: ' +
                            this.listColorToUse[i] +
                            ';margin-bottom:16px; display:block;font-weight: 900"> ― <span style="vertical-align: middle;font-family: Roboto;font-style: normal;font-size: 18px;line-height: 20px;color: #1F1F1F;font-weight: 400;">' +
                            this.rawData[i].name +
                            ': ' +
                            value +
                            '</span> </span><br/>';
                    }
                }

                tooltipContent = tooltipContent + line;
            }
            return (
                '<span style="align:left;color:#1F1F1F;font-family: Roboto;font-style: normal;font-weight: 500;font-size: 16px;line-height: 24px;">' +
                dateDay +
                '</span><br/><br/>' +
                tooltipContent
            );
        },
    },
};
</script>

<style lang="scss" scoped>
.columnChartReport {
    height: 370px;
}
.columnChartRetail {
    height: 390px;
}
</style>
