<template>
    <div id="ColumnChart" class="CustomerRadar2">
        <apexchart
            type="bar"
            height="232"
            :options="chartOptions"
            :series="series"
        ></apexchart>
    </div>
</template>
<script>
export default {
    name: "ColumnChart",
    props: {
        series: {
            type: Array
        },
        listCustomerType: []
    },
    mounted() {
        var listEmotions = []
        var lang = localStorage.lang;
        if (lang == null || lang == "vn") {
            listEmotions = [
                "Bình thường",
                "Vui vẻ",
            ]
        } else {
            listEmotions = [
                "Neutral",
                "Happy",
            ]

        }
        this.chartOptions = {
            chart: {
                height: 232,
                type: "bar"
            },
            plotOptions: {
                bar: {
                    borderRadius: 10,
                    dataLabels: {
                        position: "top" // top, center, bottom
                    }
                }
            },
            dataLabels: {
                enabled: false,
                formatter: function(val) {
                    return val;
                },
                offsetY: -20,
                style: {
                    fontSize: "14px",
                    colors: ["#0099BC"]
                }
            },
            colors: ["#0099BC"],
            xaxis: {
                categories: this.listCustomerType,
                position: "bottom",
                axisBorder: {
                    show: false
                },
                labels: {
                    show: true,
                    rotate: -35,
                    trim: true,
                    maxHeight: 50,
                    hideOverlappingLabels: false
                },
                axisTicks: {
                    show: false
                },
                crosshairs: {
                    fill: {
                        type: "gradient",
                        gradient: {
                            colorFrom: "#D8E3F0",
                            colorTo: "#BED1E6",
                            stops: [0, 100],
                            opacityFrom: 0.4,
                            opacityTo: 0.5
                        }
                    }
                }
            },
            yaxis: {
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false
                },
                tickAmount: 4
            },
            tooltip: {
                enabled: true
            }
        }
    },
    watch: {
        listCustomerType(value) {
            this.chartOptions = {
                chart: {
                    height: 232,
                    type: "bar"
                },
                colors: ["#0099BC"],
                plotOptions: {
                    bar: {
                        borderRadius: 10,
                        dataLabels: {
                            position: "top" // top, center, bottom
                        }
                    }
                },
                dataLabels: {
                    enabled: false,
                    formatter: function(val) {
                        return val;
                    },
                    offsetY: -20,
                    style: {
                        fontSize: "14px",
                        colors: ["#0099BC"]
                    }
                },

                xaxis: {
                    categories: value,
                    position: "bottom",
                    axisBorder: {
                        show: false
                    },
                    axisTicks: {
                        show: false
                    },
                    labels: {
                        rotate: -35,
                        trim: true,
                        maxHeight: 50,
                        fontFamily: "Roboto",
                        fontSize: '14px',
                        cssClass: ".testBreakWord"
                    },
                    crosshairs: {
                        fill: {
                            type: "gradient",
                            gradient: {
                                colorFrom: "#D8E3F0",
                                colorTo: "#BED1E6",
                                stops: [0, 100],
                                opacityFrom: 0.4,
                                opacityTo: 0.5
                            }
                        }
                    }
                },
                yaxis: {
                    axisBorder: {
                        show: false
                    },
                    axisTicks: {
                        show: false
                    },
                    tickAmount: 4
                },
            }
        },
        series(val) {
            let self = this
            let total = val[0].data.map(i=>Number(i)).reduce((one, two) => one + two, 0)
            this.chartOptions.tooltip = {
                enabled: true,
                marker: {
                    show: false,
                },
                y: {
                    title: {
                        formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {
                            return self.$t("CustomerReport.Total") + ": " + "<b>" + parseInt(self.series[seriesIndex]["data"][dataPointIndex]) + "</b>" + "<br>" + self.$t("CustomerReport.Percent") + ": " + "<b>" +  (((self.series[seriesIndex]["data"][dataPointIndex] / total) * 100)).toFixed(2) + " %" + "</b>"
                        },
                        style: {
                            fontSize:  '14px',
                            fontFamily: "Roboto",
                            color:  '#000000'
                        },
                    },
                    labels: {
                        fontFamily: "Roboto",
                        fontSize: '14px',
                    },
                    formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {
                        return ""
                    },
                }
            }
        }

    },
    data() {
        return {
            chartOptions: {
                chart: {
                    height: 192,
                    type: "bar"
                },
                plotOptions: {
                    bar: {
                        borderRadius: 10,
                        dataLabels: {
                            position: "top" // top, center, bottom
                        }
                    }
                },
                colors: ["#0099BC"],
                dataLabels: {
                    enabled: false,
                    formatter: function(val) {
                        return val;
                    },
                    offsetY: -20,
                    style: {
                        fontSize: "14px",
                        colors: ["#0099BC"]
                    }
                },

                xaxis: {
                    categories: ['South Korea', 'Canada', 'United Kingdom'],
                    position: "bottom",
                    axisBorder: {
                        show: false
                    },
                    axisTicks: {
                        show: false
                    },
                    crosshairs: {
                        fill: {
                            type: "gradient",
                            gradient: {
                                colorFrom: "#D8E3F0",
                                colorTo: "#BED1E6",
                                stops: [0, 100],
                                opacityFrom: 0.4,
                                opacityTo: 0.5
                            }
                        }
                    },
                    labels: {
                        rotate: -35,
                        trim: true,
                        maxHeight: 50,
                        fontFamily: "Roboto",
                        fontSize: '14px',
                        cssClass: ".CustomerRadar2.testBreakWord"
                    },
                },
                yaxis: {
                    axisBorder: {
                        show: false
                    },
                    axisTicks: {
                        show: false
                    },
                    tickAmount: 4
                },
                tooltip: {
                    enabled: false
                }
            }
        };
    }
};
</script>

<style lang="scss">

.CustomerRadar2 {
    .testBreakWord {
        word-wrap: break-word;
    }
    .apexcharts-tooltip-title {
        background: #0063B1 !important;
        color: #FFFFFF !important;
        border-radius-topleft: 5px;
        border-radius-topright: 5px;
    }
    .apexcharts-legend-marker {
        position: relative;
        display: inline-block;
        cursor: pointer;
        margin-right: 3px;
        border-style: solid;
        margin-left: 16px;
    }
}
</style>
