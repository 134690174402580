<template>
    <div class="line-chart-zone-analysis">
        <JSCharting
            :options="lineChartOptions"
            :mutable="false"
            class="columnChart"
        ></JSCharting>
    </div>
</template>

<script>
import JSCharting, {JSC} from "jscharting-vue";
import {Api} from "@/api/index";
import moment from "moment-timezone";

moment.tz.setDefault("Asia/Ho_Chi_Minh");
moment.locale("vi");

const getReportStatisticComeIn = Api.get("getReportStatisticComeIn");


export default {
    name: "LineChartZoneAnalysis",
    components: {
        JSCharting
    },
    props: {
        lineChartQuery: Object,
        firstTimeLineChart: Boolean
    },
    mounted() {
        if (localStorage.lang === "en") {
            this.listDayInWeek = ["MO ", "TU ", "WE ", "TH ", "FR ", "SA ", "SU ",]
        } else {
            this.listDayInWeek = ["T2 ", "T3 ", "T4 ", "T5 ", "T6 ", "T7 ", "CN "]
        }

        const rawData = [{"name": " ", "total": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]}]
        this.processListZoneStatistic(rawData, 1659286800, 1659286800)
    },
    data() {
        let self = this;
        return {
            listDayInWeek: [],
            dateArray: [
                "01/11/1970",
                "01/12/1970",
                "01/13/1970",
                "01/14/1970",
                "01/15/1970",
                "01/16/1970",
                "01/17/1970",
                "01/18/1970",
                "01/19/1970",
                "01/20/1970",
                "01/21/1970",
                "01/22/1970",
                "01/23/1970",
                "01/24/1970",
                "01/25/1970",
                "01/26/1970",
                "01/27/1970",
            ],
            hourArray: [
                "07h - 08h",
                "08h - 09h",
                "09h - 10h",
                "10h - 11h",
                "11h - 12h",
                "12h - 13h",
                "13h - 14h",
                "14h - 15h",
                "15h - 16h",
                "16h - 17h",
                "17h - 18h",
                "18h - 19h",
                "19h - 20h",
                "20h - 21h",
                "21h - 22h",
                "22h - 23h",
                "23h - 24h",
            ],
            lineChartOptions: {
                debug: false,
                palette: ["#004961", "#D81F41", "#68768A", "#00CC6A", "#FFB900", "#0063B1", "#B146C2"],
                type: 'line',
                legend: {
                    position: 'bottom',
                    fill: '#fffff',
                    boxVisible: true,
                    corners: 'round',
                    radius: 5,
                    margin_left: 10,
                    outline: {color: '#00000', width: 0},
                    template: '%icon,%name',
                    defaultEntry: {
                        iconWidth: 9,
                        padding: 1,
                        style: {
                            color: '#3A5254',
                            fontSize: '9.5pt',
                            fontStyle: 'italic',
                            fontFamily: 'Arial',
                            fontWeight: 'normal'
                        },
                        states: {
                            hover_style: {color: '#FF5254'},
                            hidden_style: {color: '#c2bec1'}
                        }
                    }
                },
                defaultAxis: {
                    line_width: 0,
                    alternateGridFill: "none",
                    defaultTick_gridLine_color: "#e5e5e5"
                },
                xAxis: {
                    crosshair_enabled: true,
                    scale_type: "time",
                    defaultTick: {
                        label_text: self.labelFormatter
                    },
                    scale: {
                        minorInterval: {
                            unit: "day",
                            multiplier: 1
                        }
                    },
                    minorInterval: {
                        unit: "day",
                        multiplier: 1
                    }
                },
                yAxis: {
                    scale: {
                        range: {min: 0}
                    }
                },
                defaultSeries: {
                    shape_opacity: 0.3,
                    defaultPoint_marker: {
                        fill: "white",
                        type: "circle",
                        outline: {width: 2}
                    },
                    events: {
                        legendEntryClick: function (e) {
                            return false;
                        }
                    }
                },
                defaultTooltip: {
                    padding: 15,
                    label_text: self.tooltipFormatter,
                    fill: '#FFFFFF',
                    radius: 8,
                },
                defaultPoint_tooltip: `%icon <span style="font-family: 'Roboto';font-style: normal;font-weight: 400;font-size: 14px;line-height: 20px;text-align: center;color: #1F1F1F;">%seriesName</span> <span style="color:#1F1F1F;font-family: Roboto;font-style: normal;font-weight: 400;font-size: 14px;line-height: 20px;">%yValue</span>`,
                series: []
            },
            timeRange: 0,
            dateStartObject: {},
            dateEndObject: {}
        }
    },
    methods: {
        tooltipFormatter(pts) {
            if (new Date(pts[0]["x"]) < new Date("12/27/1970")) {
                const hour = this.dateArray.indexOf(moment.unix(new Date(pts[0]["x"]) / 1000).format("MM/DD/YYYY"))
                const tooltipHeader = this.hourArray[hour]
                return (`<span style="font-family: 'Roboto';font-style: normal;font-weight: 400;font-size: 12px;line-height: 16px;color: #1F1F1F;">${tooltipHeader}</span><hr>%points`);

            }
            const dateObject = new Date(pts[0]["x"])
            const dd = String(dateObject.getDate()).padStart(2, '0');
            const mm = String(dateObject.getMonth() + 1).padStart(2, '0');
            const dateString = `${dd}/${mm}`

            return (`<span style="font-family: 'Roboto';font-style: normal;font-weight: 400;font-size: 12px;line-height: 16px;color: #1F1F1F;">${dateString}</span><hr>%points`);
        },
        getStatisticComeIn(listZoneID, dateStart, dateEnd) {
            if (listZoneID !== "") {
                getReportStatisticComeIn.get(listZoneID, dateStart, dateEnd).then(response => {
                    var rawData = response.data["data"]
                    if (this.firstTimeLineChart === true && rawData.length > 2) {
                        var maxData = []
                        for (var i = 0; i < 2; i++) {
                            var maxNumber = 0
                            var maxIndex = 0
                            for (var z = 0; z < rawData.length; z++) {
                                var sum = rawData[z].total.reduce((partiulSum, a) => partiulSum + a, 0);
                                if (maxNumber < sum) {
                                    maxNumber = sum
                                    maxIndex = z
                                }
                            }
                            maxData.push(rawData[maxIndex])
                            rawData.splice(maxIndex, 1)
                        }
                        rawData = maxData
                    }

                    if (rawData.length === 0) {
                        rawData = [{"name": " ", "total": [0, 0, 0, 0, 0, 0, 0]}]
                    }
                    this.processListZoneStatistic(rawData, dateStart, dateEnd)
                }).catch(() => {
                    this.$set(this.lineChartOptions, "series", [])
                })
            }
        },
        getLabelTimeRangeOver180(timestampFordate) {
            let weekDay = "";

            if (moment.unix(timestampFordate / 1000).format("DD") !== "01") {
                return ""
            }

            if (parseInt(this.dateEndObject.format("YYYY")) < parseInt(moment.unix(timestampFordate / 1000).format("YYYY"))) {
                if (moment.unix((timestampFordate - 1) / 1000).day() - 1 === -1) {
                    weekDay = this.listDayInWeek[this.listDayInWeek.length - 1];
                } else {
                    weekDay = this.listDayInWeek[moment.unix((timestampFordate - 1) / 1000).day() - 1];
                }
                let date = moment
                    .unix((timestampFordate - 1) / 1000)
                    .format("DD/MM");
                return weekDay + date;
            } else {
                if (moment.unix((timestampFordate) / 1000).day() - 1 === -1) {
                    weekDay = this.listDayInWeek[this.listDayInWeek.length - 1];
                } else {
                    weekDay = this.listDayInWeek[moment.unix((timestampFordate) / 1000).day() - 1];
                }
                let date = moment
                    .unix((timestampFordate) / 1000)
                    .format("DD/MM");
                return weekDay + date;
            }

        },
        getLabelTimeRangeBetween30And180(timestampFordate) {
            if (moment.unix(timestampFordate / 1000).day() - 1 === 0) {
                let weekDay = this.listDayInWeek[0];
                let hour = moment
                    .unix(timestampFordate / 1000)
                    .format("DD/MM");
                return weekDay + hour;
            }
            return "";
        },
        getLabelTimeRangeUnder30(timestampFordate) {
            let weekDay = "";
            if (moment.unix(timestampFordate / 1000).day() - 1 === -1) {
                weekDay = this.listDayInWeek[this.listDayInWeek.length - 1];
            } else {
                weekDay = this.listDayInWeek[moment.unix(timestampFordate / 1000).day() - 1];
            }
            const dateObject = new Date(timestampFordate)
            if (dateObject.getHours() === 0) {
                const date = dateObject.getDate().toString().padStart(2, '0')
                const month = (dateObject.getMonth() + 1).toString().padStart(2, '0')
                return `${weekDay} ${date}/${month}`;
            }

            return ""
        },
        labelFormatter(timestampFordate) {
            if (timestampFordate > new Date("12/27/1970")) {
                if (this.timeRange > 180) {
                    return this.getLabelTimeRangeOver180(timestampFordate)
                }

                if (this.timeRange > 30) {
                    return this.getLabelTimeRangeBetween30And180(timestampFordate)
                }

                return this.getLabelTimeRangeUnder30(timestampFordate)
            }

            let hourRange = this.dateArray.indexOf(moment.unix(timestampFordate / 1000).format("MM/DD/YYYY"))

            if (new Date(timestampFordate).getHours() === 0) {
                return this.hourArray[hourRange];
            }

            return "";
        },
        processListZoneStatistic(listZoneStatistic, dateStart, dateEnd) {
            const dateEndObject = moment.unix(dateEnd)

            let start = moment.unix(dateStart)

            let listTime = []
            if (dateStart !== dateEnd) {
                while (start.isSameOrBefore(dateEndObject)) {
                    listTime.push(start.format("MM/DD/YYYY"))
                    start.add(1, "days")
                }
            } else {
                listTime = this.dateArray
            }

            let series = []

            for (let zoneStatistic of listZoneStatistic) {
                let point = []
                listTime.forEach(function (date, index) {
                    point.push([date, zoneStatistic["total"][index]])
                })
                series.push({
                    name: zoneStatistic["name"],
                    points: point,
                    defaultPoint: {
                        marker: {
                            outline: {
                                width: 1,
                                color: "white"
                            },
                            fill: "currentColor",
                            type: "circle",
                            visible: true,
                            size: 10
                        }
                    },
                    line: {
                        width: 2
                    },
                })
            }

            this.$set(this.lineChartOptions, "series", series)
        }
    },
    watch: {
        lineChartQuery: function (lineChartQuery) {
            this.dateStartObject = moment.unix(lineChartQuery.dateRange["dateStart"])
            this.dateEndObject = moment.unix(lineChartQuery.dateRange["dateEnd"])
            this.timeRange = this.dateEndObject.diff(this.dateStartObject, "days") + 1
            this.getStatisticComeIn(lineChartQuery.listZoneID, lineChartQuery.dateRange["dateStart"], lineChartQuery.dateRange["dateEnd"])
        },
        firstTimeLineChart(val) {
        }
    }
}
</script>

<style lang="scss" scoped>

</style>    