<template>
    <div id="chart" class="CustomerRadar">
        <apexchart type="radar" height="260" :options="chartOptions" :series="series"></apexchart>
      </div>
</template>
<script>
export default {
    name: "RadarCustomer",
    props: {
        series: {
            type: Array
        },
        customerSegmentSelect: {
            type: String
        }
    },
    data() {
        return {
            chartOptions: {
                
            },
        };
    },
    watch: {
        series(val) {
            let self = this
            let total = val[0]["data"].map(i=>Number(i)).reduce((a, b) => a +b, 0) + val[1]["data"].map(i=>Number(i)).reduce((a, b) => a +b, 0)             
            this.chartOptions = {
                chart: {
                    height: 260,
                    type: 'radar',
                },
                colors: ["#2D7D9A", "#FF8C00"],
                legend: {
                    position: 'bottom',
                    markers: {
                        radius: 1,
                    },
                    fontWeight: 600,
                    fontSize: '14px',
                    fontFamily: 'Roboto'
                },
                title: {
                    text: 'Basic Radar Chart'
                },
                xaxis: {
                    categories: ['10-16', "17-24", "25-34", "35-45", "46-60", ">60"],
                    labels: {
                        rotate: 0,
                        maxHeight: 120,
                        fontFamily: "Roboto",
                        fontSize: '14px',
                    },
                },
                yaxis: {
                    show: false
                },
                tooltip: {
                    marker: {
                        show: false,
                    },
                    y: {
                        title: {
                            formatter: function (value, {series, seriesIndex, dataPointIndex, w}) {
                                const getCountPercent = (((parseInt(series[seriesIndex][dataPointIndex]) / total) * 100)).toFixed(0)
                                let percent = (!isNaN(parseInt(getCountPercent)) ? getCountPercent + " %" : self.$t("dashboard.NoData"))
                                return self.$t("CustomerReport.Total") + ": " + "<b>" + parseInt(series[seriesIndex][dataPointIndex]) + "</b>" + "<br>" + self.$t("CustomerReport.Percent") + ": " + "<b>" + percent + "</b>"
                                
                            }
                        },
                        style: {
                            fontSize:  '14px',
                            fontFamily: "Roboto",
                            color:  '#000000'
                        },
                        formatter: function (value, {series, seriesIndex, dataPointIndex, w}) {
                            return ""
                        },
                    },
                    x: {
                        formatter: function (value, {series, seriesIndex, dataPointIndex, w}) {
                            let textResponse
                            let findAllElemZeroValueMale = self.series[0]["data"].reduce(function(a, e, i) {
                                if (e === 0)
                                    a.push(i);
                                return a;
                            }, [])
                            let findAllElemZeroValueFemale = self.series[1]["data"].reduce(function(a, e, i) {
                                if (e === 0)
                                    a.push(i);
                                return a;
                            }, [])
                            if (total === 0) {
                                textResponse = self.customerSegmentSelect
                                    + "<br>" + val[0]["name"] + ", " + self.chartOptions.xaxis.categories
                                    + " " + self.$t("CustomerReport.Age") + "<br>" +
                                    val[1]["name"] + ", " + self.chartOptions.xaxis.categories
                                    + " " + self.$t("CustomerReport.Age")
                            } else {
                                textResponse = self.customerSegmentSelect
                                    + "<br>" + self.series[seriesIndex]["name"] + ", "
                                    + self.chartOptions.xaxis.categories[dataPointIndex]
                                    + " " + self.$t("CustomerReport.Age")
                                
                            }
                            
                            if (seriesIndex == 0 && findAllElemZeroValueMale.includes(dataPointIndex)) {
                                let maleRangeZero = []
                                let femaleRangeZero= []
                                for (const age of findAllElemZeroValueMale) {
                                    maleRangeZero.push(self.chartOptions.xaxis.categories[age])
                                }
                                for (const age of findAllElemZeroValueFemale) {
                                    femaleRangeZero.push(self.chartOptions.xaxis.categories[age])
                                }
                                textResponse = self.customerSegmentSelect
                                
                                if (maleRangeZero.length > 0) {
                                    textResponse = textResponse + "<br>" + self.series[0]["name"] + ", "
                                        + maleRangeZero + " " + self.$t("CustomerReport.Age")
                                }
                                
                                if (femaleRangeZero.length > 0) {
                                    textResponse = textResponse + "<br>" + self.series[1]["name"] + ", "
                                        + femaleRangeZero + " " + self.$t("CustomerReport.Age")
                                }                                 
                            } else if (seriesIndex == 1 && findAllElemZeroValueFemale.includes(dataPointIndex)) {
                                let maleRangeZero = []
                                let femaleRangeZero= []
                                for (const age of findAllElemZeroValueMale) {
                                    maleRangeZero.push(self.chartOptions.xaxis.categories[age])
                                }
                                for (const age of findAllElemZeroValueFemale) {
                                    femaleRangeZero.push(self.chartOptions.xaxis.categories[age])
                                }
                                textResponse = self.customerSegmentSelect

                                if (maleRangeZero.length > 0) {
                                    textResponse = textResponse + "<br>" + self.series[0]["name"] + ", "
                                        + maleRangeZero + " " + self.$t("CustomerReport.Age")
                                }

                                if (femaleRangeZero.length > 0) {
                                    textResponse = textResponse + "<br>" + self.series[1]["name"] + ", "
                                        + femaleRangeZero + " " + self.$t("CustomerReport.Age")
                                }
                            }
                            return textResponse
                        },
                        labels: {
                            fontFamily: "Roboto",
                            fontSize: '14px',
                        },
                        style: {
                            fontSize:  '14px',
                            fontFamily: "Roboto",
                            color:  '#000000'
                        },
                    }
                }
            }
        }
    }
};
</script>
<style lang="scss">
.CustomerRadar {
    .apexcharts-tooltip-title {
        background: #0063B1 !important;
        color: #FFFFFF !important;
        border-radius-topleft: 5px;
        border-radius-topright: 5px;
    }
    .apexcharts-legend-marker {
        position: relative;
        display: inline-block;
        cursor: pointer;
        margin-right: 3px;
        border-style: solid;
        margin-left: 16px;
    }
}

</style>