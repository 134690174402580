<template>
    <div id="chart" class="customerGender">
        <apexchart type="pie" width="312" :options="chartOptions" :series="series"></apexchart>
      </div>
</template>
<script>
export default {
    name: "CustomerGender",
    props: {
        series: {
            type: Array
        }
    },
    watch: {
        series(val) {
            let total = val.map(i=>Number(i)).reduce((a, b) => a +b, 0)
            let self = this
            this.chartOptions = {
                chart: {
                    width: 292,
                    type: 'pie',
                },
                colors: ["#2D7D9A", "#FF8C00"],
                legend: {
                    position: 'bottom',
                    markers: {
                        radius: 1,
                    },
                    fontWeight: 600,
                    fontSize: '14px',
                    fontFamily: 'Roboto'
                },
                xaxis: {
                    labels: {
                        rotate: 0,
                        maxHeight: 120,
                        fontFamily: "Roboto",
                        fontSize: '14px',
                    },  
                },
                marker: {
                    show: false,
                },
                tooltip: {
                    enabled: true,
                    y: {
                        formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {
                            return ""
                        },
                        title: {
                            formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {
                                let percent
                                if (!isNaN(parseInt((((series[seriesIndex]) / total) * 100)).toFixed(0)) ) {
                                    percent = (((parseInt(series[seriesIndex]) / total) * 100)).toFixed(0) + " %"   
                                } else percent = self.$t("dashboard.NoData")
                                return self.chartOptions.labels[seriesIndex] + "<br>" + self.$t("CustomerReport.Total") + ": " + parseInt(series[seriesIndex]) + "<br>" + self.$t("CustomerReport.Percent") + ": " + percent
                            },
                            style: {
                                fontSize:  '14px',
                                fontFamily: "Roboto",
                                color:  '#000000'
                            },
                        },
                        labels: {
                            fontFamily: "Roboto",
                            fontSize: '14px',
                        },
                    },
                },
                labels: [self.$t("dashboard.Male"), self.$t("dashboard.Female")],
                dataLabels: {
                    enable: false,
                    formatter: function (el) {
                        return ""
                    }
                }
            }
            
        }  
    },
    data() {
        return {
            chartOptions: {
                
            },
        };
    }
};
</script>
<style lang="scss">
.customerGender {
    .apexcharts-tooltip-title {
        background: #0063B1 !important;
        color: #FFFFFF !important;
        border-radius-topleft: 5px;
        border-radius-topright: 5px;
    }
    .apexcharts-legend-marker {
        position: relative;
        display: inline-block;
        cursor: pointer;
        margin-right: 3px;
        border-style: solid;
        margin-left: 16px;
    }
}

</style>