<template>
    <div class="LineChart">
        <div class="HeaderLineChart ">
            <div class="TitleLineChart">
                {{$t("Report.TrendingInStore").toUpperCase()}}
                </div>
                <div class="Btn-LineChart"
                @click="chooseTrendingOption()"
                v-click-outside="handleClickOutSideTrending">
                    {{ atributeTrendingOption }}
                    <span class="material-icons"
                    >arrow_drop_down</span
                    >
                    <div
                        ref="wrapTrendingOption"
                        class="wrapOption"
                    >
                        <div
                            class="Option"
                            @click="changeOptionTrending('Passby')"
                        >
                            {{ $t("dashboard.Passby") }}
                        </div>
                        <div
                            class="Option"
                            @click="changeOptionTrending('Footfall')"
                        >
                            {{ $t("dashboard.Footfall") }}
                        </div>
                        <div
                            class="Option"
                            @click="changeOptionTrending('Transactions')"
                        >
                            {{ $t("dashboard.Transactions") }}
                        </div>
                        <div
                            class="Option"
                            @click="changeOptionTrending('Dwelltime')"
                        >
                            {{ $t("dashboard.Dwelltime") }}
                        </div>
                        <div
                            class="Option"
                            @click="changeOptionTrending('TurnInRate')"
                        >
                            {{ $t("dashboard.TurnInRate") }}
                        </div>
                        <div
                            class="Option"
                            @click="changeOptionTrending('SaleConversionRate')"
                        >
                            {{ $t("dashboard.SaleConversionRate") }}
                        </div>
                    </div>
                </div>
        </div>
        <div class="ChartLine mt-16">
            <LineChartForReportShoppingMallDay
                typeReport="retail"
                :maxNumber="maxNumber"
                :rawData="dataGateTrending"
                :textLabel="labelText"
                :listColorToUse="listColorToUse"
                v-if="listDateRange.length == 1 && (atributeTrendingOptionRaw == 'gate' || atributeTrendingOptionRaw == 'shop' || atributeTrendingOptionRaw == 'way')"></LineChartForReportShoppingMallDay>
            <LineChartForReportShoppingMallWeek
                typeReport="retail"
                :maxNumber="maxNumber"
                :textLabel="labelText"
                :rawData="dataGateTrending"
                :listColorToUse="listColorToUse"
                v-if="listDateRange.length == 1 && (atributeTrendingOptionRaw == 'rateGate' || atributeTrendingOptionRaw == 'rateShop' || atributeTrendingOptionRaw == 'timeAverage')"></LineChartForReportShoppingMallWeek>
            <LineChartForReportShoppingMallWeek
                typeReport="retail"
                :maxNumber="maxNumber"
                :textLabel="labelText"
                :rawData="dataGateTrending"
                :listColorToUse="listColorToUse"
                v-if="listDateRange.length >= 2 && listDateRange.length<22"
            ></LineChartForReportShoppingMallWeek>
            <LineChartForReportShoppingMallMonth
                typeReport="retail"
                :maxNumber="maxNumber"
                :textLabel="labelText"
                :rawData="dataGateTrending"
                :listColorToUse="listColorToUse"
                v-else-if="listDateRange.length>=22 && listDateRange.length < 181"
            ></LineChartForReportShoppingMallMonth>
            <LineChartForReportShoppingMallYear
                typeReport="retail"
                :maxNumber="maxNumber"
                :textLabel="labelText"
                :rawData="dataGateTrending"
                :listColorToUse="listColorToUse"
                v-else-if="listDateRange.length>=181"
            ></LineChartForReportShoppingMallYear>
            <div class="Bottom" v-if="dataGateTrending.length>0">
                <div class="today">
                    <div class="ColorToday mr-8">

                    </div>
                    {{ atributeTrendingOption }}
                </div>
            </div>
        </div>
        <div class="HiddenTrial" v-if="dataGateTrending.length>0">
        </div>
    </div>
</template>

<script>
import * as moment from "moment-timezone";
moment.tz.setDefault("Asia/Ho_Chi_Minh");
moment.locale("vi");

import LineChartForReportShoppingMallDay from "./LineChartForReportShoppingMallDay.vue"
import LineChartForReportShoppingMallWeek from "./LineChartForReportShoppingMallWeek.vue"
import LineChartForReportShoppingMallMonth from "./LineChartForReportShoppingMallMonth.vue"
import LineChartForReportShoppingMallYear from "./LineChartForReportShoppingMallYear.vue"

import { Api } from "@/api/index";
const trendingReportRetail = Api.get("trendingReportRetail")

export default {
    name: "LineChartWrapper",
    props: {
        dateRange: {
            type: Object
        },
        choosenGroupID: {
            type: String
        }
    },
    mounted() {
        this.dateStart = this.dateRange.dateStart
        this.dateEnd = this.dateRange.dateEnd
        this.getTrendingData()
    },
    watch: {
        dateRange: {
            handler: function(val) {
                this.dateStart = val["dateStart"]
                this.dateEnd = val["dateEnd"]
                this.getTrendingData()
                this.listDateRange = []
                
            },
            deep: true
        },
        choosenGroupID() {
            this.getTrendingData()
        }
    },
    components: {
        LineChartForReportShoppingMallDay,
        LineChartForReportShoppingMallWeek,
        LineChartForReportShoppingMallMonth,
        LineChartForReportShoppingMallYear,
    },
    data() {
        let self = this
        return {
            labelText: "",
            dateStart: 0,
            dateEnd: 0,
            dataGateTrending: [],
            atributeTrendingOption: self.$t("dashboard.Footfall"),
            atributeTrendingOptionRaw: "gate",
            maxNumber: 0,
            listColorToUse:["#D81F41"],
            listDateRange: [
                moment()
                    .subtract(7, "day")
                    .startOf("day")
                    .format("DD/MM"),
                moment()
                    .subtract(6, "day")
                    .startOf("day")
                    .format("DD/MM"),
                moment()
                    .subtract(5, "day")
                    .startOf("day")
                    .format("DD/MM"),
                moment()
                    .subtract(4, "day")
                    .startOf("day")
                    .format("DD/MM"),
                moment()
                    .subtract(3, "day")
                    .startOf("day")
                    .format("DD/MM"),
                moment()
                    .subtract(2, "day")
                    .startOf("day")
                    .format("DD/MM"),
                moment()
                    .subtract(1, "day")
                    .startOf("day")
                    .format("DD/MM")
            ],
        }
        
    },
    methods: {
        chooseTrendingOption() {
            this.$refs["wrapTrendingOption"].style.position = "absolute";
            this.$refs["wrapTrendingOption"].style.display = "block";
            this.$refs["wrapTrendingOption"].style.visibility = "visible";
        },
        handleClickOutSideTrending() {
            this.$refs["wrapTrendingOption"].style.display = "none";
            this.$refs["wrapTrendingOption"].style.visibility = "hidden";
        },
        changeOptionTrending(option) {
            let self = this;
            setTimeout(() => {
                self.handleClickOutSideTrending();
            }, 100);
            this.atributeTrendingOption = this.$t("dashboard." + option);
            if (this.atributeTrendingOption == this.$t("dashboard.Passby")) {
                this.atributeTrendingOptionRaw = "way"
                this.labelText = ""
            } else if (this.atributeTrendingOption == this.$t("dashboard.Footfall")) {
                this.atributeTrendingOptionRaw = "gate"
                this.labelText = ""
            } else if (this.atributeTrendingOption == this.$t("dashboard.TurnInRate")) {
                this.atributeTrendingOptionRaw = "rateGate"
                this.labelText = "%"
            } else if (this.atributeTrendingOption == this.$t("dashboard.SaleConversionRate")) {
                this.atributeTrendingOptionRaw = "rateShop"
                this.labelText = "%"
            } else if (this.atributeTrendingOption == this.$t("dashboard.Dwelltime")) {
                this.atributeTrendingOptionRaw = "timeAverage"
                this.labelText = "s"
            } else {
                this.atributeTrendingOptionRaw = "shop"
                this.labelText = ""
            }
            this.getTrendingData()
            
        },
        getTrendingData() {
            this.dataGateTrending = []
            trendingReportRetail.get(this.dateStart, this.dateEnd, this.atributeTrendingOptionRaw, this.choosenGroupID).then(response => {
                this.listDateRange = []
                for (let i = parseInt(this.dateStart); i <= parseInt(this.dateEnd); i = i + 86400) {
                    this.listDateRange.push(moment
                        .unix(i)
                        .startOf("day")
                        .format("M/DD/YYYY")
                    ) 
                }
                let rawData = response.data.data
                for (let index in rawData) {
                    if (rawData[index] == 100000001) {
                        rawData[index] = 0
                    }
                }
                let dataGateTrending = []
                let pointData = []
                let maxNumber = Math.max(...rawData) 
                if (maxNumber < 100) {
                    this.maxNumber = this.round5(Math.max(...rawData))
                } else if (maxNumber >= 100 && maxNumber < 500) {
                    this.maxNumber = this.round100(Math.max(...rawData))
                } else if (maxNumber >= 500) {
                    this.maxNumber = this.round500(Math.max(...rawData))
                }
                if (this.dateRange.dateStart - this.dateRange.dateEnd == 0 && 
                    (this.atributeTrendingOptionRaw == "gate" || 
                    this.atributeTrendingOptionRaw == "shop" ||
                    this.atributeTrendingOptionRaw == "way")) {
                    let hour = [
                        "1/11/2020",
                        "1/12/2020",
                        "1/13/2020",
                        "1/14/2020",
                        "1/15/2020",
                        "1/16/2020",
                        "1/17/2020",
                        "1/18/2020",
                        "1/19/2020",
                        "1/20/2020",
                        "1/21/2020",
                        "1/22/2020",
                        "1/23/2020",
                        "1/24/2020",
                        "1/25/2020",
                        "1/26/2020",
                        "1/27/2020"
                    ];
                    for (let index = 0; index < rawData.length; index++) {
                        pointData.push([hour[index], rawData[index]])
                    }
                } else {
                    for (let index = 0; index < rawData.length; index++) {
                        pointData.push([this.listDateRange[index], rawData[index]])
                    }
                }
                let dataOfOneGate = {
                    name: this.atributeTrendingOption,
                    defaultPoint: {
                        marker: {
                            outline: {
                                width: 1,
                                color: 'white'
                            },
                            fill: "#D81F41",
                            type: 'circle',
                            visible: true,
                            size: 12
                        }
                    },
                    line: {
                        color: "#D81F41",
                        width: 2,

                    },
                    points: pointData
                }
                dataGateTrending.push(dataOfOneGate)
                let self = this
                setTimeout(() => {
                    self.dataGateTrending = dataGateTrending
                }, 1000);
                
            })
        },
        round5(x) {
            return Math.ceil(x / 5) * 5;
        },
        round100(x) {
            return Math.ceil(x / 100) * 100;
        },
        round500(x) {
            return Math.ceil(x / 500) * 500;
        },
    }   
}
</script>

<style lang="scss">
.LineChart {
    background: #FFFFFF;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
    height: 100%;
    padding: 24px;
    .HiddenTrial {
        width: 50px;
        height: 50px;
        background-color: #FFFFFF;
        position: absolute;
        bottom: 20;
        left: 20;
        z-index: 2;
        margin-top:-43px
    }
    .HeaderLineChart {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        .TitleLineChart {
            font-family: 'Roboto';
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
            color: #1F1F1F;
        }
        .Btn-LineChart {
            padding: 8px 8px;
            border: 1px solid #8C8C8C;
            border-radius: 8px;
            display: flex;
            align-items: center;
            font-family: 'Roboto';
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #434343;
            position: relative;
            cursor: pointer;
            margin-top: -8px;
            .wrapOption {
                overflow: auto;
                background: #ffffff;
                box-sizing: border-box;
                box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.05);
                border-radius: 8px;
                position: absolute;
                width: 250px !important;
                top: 43px;
                z-index: 9999;
                // visibility: hidden;
                max-height: 312px;
                overflow: auto;
                .Option {
                    padding: 16px;
                    font-family: "Roboto";
                    font-size: 14px;
                    line-height: 150%;
                    color: #333333;
                    min-width: 180px;
                    font-weight: 400;
                    cursor: pointer;
                    &:hover {
                        background-color: #f6f9ff;
                    }
                }
            }
        }
    }
    .Bottom {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .today {
            display: flex;
            align-items: center;
            margin-right: 40px;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #333333;
            .ColorToday {
                width: 16px;
                height: 16px;
                border-radius: 50%;
                background-color: #D81F41;
            }
        }
        .yesterday {
            display: flex;
            align-items: center;
            font-family: 'Roboto';
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #1F1F1F;
            .ColorYesterday {
                width: 16px;
                height: 16px;
                border-radius: 50%;
                background-color: #104579;
            }
        }
    }
}
</style>