<template>
    <div ref="chartContainer" class="chart-container">
        <canvas id="lines" width="500" height="500"></canvas>
        <div id="quadrant-chart-container" class="quadrant-chart-container"></div>
    </div>
</template>

<script>
import FusionCharts from "fusioncharts";
import Charts from "fusioncharts/fusioncharts.charts";
import {FCComponent} from "vue-fusioncharts";

Charts(FusionCharts);

import {Api} from "@/api/index";
import moment from "moment-timezone";

moment.tz.setDefault("Asia/Ho_Chi_Minh");
moment.locale("vi");

const getStatisticInteractionRate = Api.get("getStatisticInteractionRate");


export default {
    name: "QuadrantChartInteractionRate",
    props: {
        quadrantChartQuery: Object,
    },
    components: {},
    mounted() {
        const width = 450
        let linesCanvas = document.getElementById('lines')
        linesCanvas.setAttribute("width", width)

        let linesContext = linesCanvas.getContext("2d")
        this.drawHorizonArrow(linesContext, 10, 490, width, 490, 2, 'black')
        this.drawVerticalArrow(linesContext, 25, 480, 25, 80, 2, 'black')
    },
    data() {
        var self = this
        return {
            chartObject: {
                type: "bubble",
                renderAt: "quadrant-chart-container",
                width: "400",
                height: "432",
                dataFormat: "json",
                dataSource: {}
            },
            dataSource: {
                chart: {
                    theme: "fusion",
                    xaxisminvalue: "0",
                    xaxismaxvalue: "2500",
                    yaxisminvalue: "0",
                    yaxismaxvalue: "1000",
                    quadrantLabelFont: "Roboto",
                    quadrantLabelFontSize: 14,
                    quadrantLabelFontColor: "#FFFFFF",
                    quadrantLabelFontBold: 1,
                    quadrantLabelPadding: 8,
                    quadrantLabelTL: self.$t("reportShopingMall.challenge").toUpperCase(),
                    quadrantLabelTR: self.$t("reportShopingMall.safe").toUpperCase(),
                    quadrantLabelBL: self.$t("reportShopingMall.risk").toUpperCase(),
                    quadrantLabelBR: self.$t("reportShopingMall.opportunities").toUpperCase(),
                    bgImage: "/img/chart_background.jpeg",
                    bgImageAlpha: "100",
                    bgImageVAlign: "top",
                    bgImageHAlign: "middle",
                    showValues: "1",
                    valueFont: "Roboto",
                    valueFontColor: "#FFFFFF",
                    valueFontSize: 14,
                    valueFontBold: true,
                    showLabels: "0",
                    showYAxisValues: "0",
                    plottooltext: self.$t("dashboard.Footfall") + `: $xvalue <br/>` + self.$t("reportShopingMall.interactionTime") + `: $yvalues`,
                    // plottooltext: self.$t("dashboard.Footfall") + `: $xDataValue <br/>` + self.$t("reportShopingMall.interactionTime") + `: $yDataValue`,
                    drawquadrant: "1",
                    quadrantxval: "54",
                    quadrantyval: "12000",
                    quadrantlinealpha: "100",
                    quadrantlinethickness: "0",
                    divLineAlpha: 0,
                    vdivLineAlpha: 0
                },
                // 370 370 327 309
                dataset: [],
                // trendlines: [
                //     {
                //         line: [
                //             {
                //                 startvalue: "0",
                //                 endvalue: "500",
                //                 istrendzone: "1",
                //                 color: "#7BBEFF4D",
                //                 alpha: "64",
                //                 displayvalue: " ",
                //                 drawQuadrant: "1"
                //             }
                //         ]
                //     }
                // ],
                // vtrendlines: [{
                //     line: [{
                //         startValue: "0",
                //         endValue: "54",
                //         isTrendZone: "1",
                //         color: "#064B6C",
                //         alpha: "60",
                //         showTrendlinesOnTop: true,
                //         displayvalue: " ",
                //         drawQuadrant: "1"
                //     }]
                // }]
            }
        }
    },
    methods: {
        getStatisticInteractionRate(groupID, dateStart, dateEnd) {
            getStatisticInteractionRate.get(groupID, dateStart, dateEnd).then(response => {
                this.processQuadrantChart(response.data)
            })
        },
        processQuadrantChart(quadrantChartData) {
            let dataSource = this.dataSource

            for (let data of quadrantChartData.data) {
                data["color"] = "#0063B1"
            }

            dataSource.dataset = [{"data": quadrantChartData.data}]
            dataSource.chart.quadrantxval = quadrantChartData["xval"]
            dataSource.chart.quadrantyval = quadrantChartData["yval"]

            let xmax = 0
            let ymax = 0
            for (let zoneData of quadrantChartData["data"]) {
                if (Math.abs(zoneData["x"] - quadrantChartData["xval"]) > xmax) {
                    xmax = Math.abs(zoneData["x"] - quadrantChartData["xval"])
                }

                if (Math.abs(zoneData["y"] - quadrantChartData["yval"]) > ymax) {
                    ymax = Math.abs(zoneData["y"] - quadrantChartData["yval"])
                }
            }

            dataSource.chart.xaxismaxvalue = dataSource.chart.quadrantxval + xmax
            dataSource.chart.yaxismaxvalue = dataSource.chart.quadrantyval + ymax + 0.5

            dataSource.chart.xaxisminvalue = dataSource.chart.quadrantxval - xmax
            dataSource.chart.yaxisminvalue = dataSource.chart.quadrantyval - ymax - 0.5

            let trendLine = {
                line: [{
                    startvalue: dataSource.chart.yaxisminvalue,
                    endvalue: dataSource.chart.quadrantyval,
                    istrendzone: "1",
                    color: "#7BBEFF4D",
                    alpha: "64",
                    displayvalue: " "
                }]
            }

            let vTrendline = {
                line: [{
                    startValue: dataSource.chart.xaxisminvalue,
                    endValue: dataSource.chart.quadrantxval,
                    isTrendZone: "1",
                    color: "#064B6C",
                    alpha: "60",
                    showTrendlinesOnTop: true,
                    displayvalue: " "
                }]
            }

            // dataSource.trendlines = [trendLine]
            // dataSource.vtrendlines = [vTrendline]

            this.renderChart(dataSource)
        },
        renderChart(dataSource) {
            let chartObject = this.chartObject
            chartObject.dataSource = dataSource

            FusionCharts.ready(function () {
                const bubbleQuadrantChart = new FusionCharts(chartObject);
                bubbleQuadrantChart.render();
            });
        },
        drawHorizonArrow(ctx, fromx, fromy, tox, toy, arrowWidth, color) {
            let headlen = 10;
            let angle = Math.atan2(toy - fromy, tox - fromx);

            ctx.save();
            ctx.strokeStyle = color;

            ctx.font = "italic 14px Roboto"
            ctx.textBaseline = "middle";
            ctx.fillStyle = "#D81F41"
            let textLow = this.$t("reportShopingMall.low")
            ctx.fillText(textLow, fromx, fromy)

            // draw root line
            ctx.beginPath();
            fromx = fromx + ctx.measureText(textLow).width + 4
            ctx.moveTo(fromx, fromy);
            ctx.lineTo(tox * 25 / 100, fromy);
            ctx.lineWidth = arrowWidth;
            ctx.stroke();
            fromx = tox * 25 / 100

            // add text
            ctx.font = "italic 14px Roboto"
            ctx.fillStyle = "#5C6066"
            ctx.textBaseline = "middle";
            let totalCustomer = this.$t("reportShopingMall.numberOfCustomer")
            ctx.fillText(totalCustomer, fromx, fromy)

            fromx = fromx + ctx.measureText(totalCustomer).width + 3
            ctx.beginPath();
            ctx.moveTo(fromx, fromy);
            ctx.lineTo(tox * 75.5 / 100, fromy);
            ctx.lineWidth = arrowWidth;
            ctx.stroke();

            // add text
            fromx = tox * 76 / 100
            ctx.font = "italic 14px Roboto"
            ctx.textBaseline = "middle";
            ctx.fillStyle = "#D81F41"
            let textHeight = this.$t("reportShopingMall.high")
            ctx.fillText(textHeight, fromx, fromy)

            fromx = fromx + ctx.measureText(textHeight).width + 3
            ctx.beginPath();
            ctx.moveTo(fromx, fromy);
            ctx.lineTo(tox * 98 / 100, toy);
            ctx.lineWidth = arrowWidth;
            ctx.stroke();

            tox = tox * 98 / 100
            ctx.beginPath();
            ctx.moveTo(tox, toy);
            ctx.lineTo(tox - headlen * Math.cos(angle - Math.PI / 7),
                toy - headlen * Math.sin(angle - Math.PI / 7));

            ctx.lineTo(tox - headlen * Math.cos(angle + Math.PI / 7),
                toy - headlen * Math.sin(angle + Math.PI / 7));

            ctx.lineTo(tox, toy);
            ctx.lineTo(tox - headlen * Math.cos(angle - Math.PI / 7),
                toy - headlen * Math.sin(angle - Math.PI / 7));
            ctx.lineWidth = 4;
            ctx.stroke();
            ctx.restore();
        },
        drawVerticalArrow(ctx, fromx, fromy, tox, toy, arrowWidth, color) {
            let headlen = 10;
            let angle = Math.atan2(toy - fromy, tox - fromx);

            ctx.save();
            ctx.strokeStyle = color;

            // draw root line
            ctx.beginPath();
            ctx.moveTo(fromx, fromy);
            fromy = fromy - fromy * 15 / 100
            ctx.lineTo(fromx, fromy);
            ctx.lineWidth = arrowWidth;
            ctx.stroke();

            // add text
            ctx.font = "italic 14px Roboto"
            ctx.fillStyle = "#5C6066"
            ctx.textBaseline = "middle";
            ctx.translate(fromx, fromy)
            ctx.rotate(-Math.PI / 2);
            let textInteractionTime = this.$t("reportShopingMall.interactionTime")
            ctx.fillText(textInteractionTime, 0, 0)
            ctx.restore()

            fromy = fromy - ctx.measureText(textInteractionTime).width - 35
            ctx.beginPath();
            ctx.moveTo(fromx, fromy);
            fromy = fromy - fromy * 30 / 100
            ctx.lineTo(fromx, fromy);
            ctx.lineWidth = arrowWidth;
            ctx.save()
            ctx.stroke();

            // add text
            ctx.font = "italic 14px Roboto"
            ctx.textBaseline = "middle";
            ctx.fillStyle = "#D81F41"
            ctx.translate(fromx, fromy - 3)
            ctx.rotate(-Math.PI / 2);
            let textHeight = this.$t("reportShopingMall.high")
            ctx.fillText(textHeight, 0, 0)
            ctx.restore()

            ctx.beginPath();
            ctx.translate(0, 0)
            fromy = fromy - ctx.measureText(textHeight).width - 15
            ctx.moveTo(fromx, fromy);
            fromy = fromy - fromy * 100 / 100
            ctx.lineTo(tox, toy);
            ctx.lineWidth = arrowWidth;
            ctx.stroke();

            ctx.beginPath();
            ctx.moveTo(tox, toy);
            ctx.lineTo(tox - headlen * Math.cos(angle - Math.PI / 7),
                toy - headlen * Math.sin(angle - Math.PI / 7));

            ctx.lineTo(tox - headlen * Math.cos(angle + Math.PI / 7),
                toy - headlen * Math.sin(angle + Math.PI / 7));

            ctx.lineTo(tox, toy);
            ctx.lineTo(tox - headlen * Math.cos(angle - Math.PI / 7),
                toy - headlen * Math.sin(angle - Math.PI / 7));
            ctx.lineWidth = 4;
            ctx.stroke();
            ctx.restore();
        }
    },
    watch: {
        quadrantChartQuery: function (quadrantChartQuery) {
            this.getStatisticInteractionRate(quadrantChartQuery.groupID, quadrantChartQuery.dateRange["dateStart"], quadrantChartQuery.dateRange["dateEnd"])
        }
    }
}
</script>

<style lang="scss" scoped>
.chart-container {
    position: relative;

    .quadrant-chart-container {
        position: absolute;
        top: 84px;
        left: 34px;
        height: 382px;
        overflow: hidden;
    }
}
</style>
