<template>
    <div style="width: 105%;" class="chartWeek">
        <JSCharting :options="chartOptions" class="columnChart"></JSCharting>
        <div class="HiddenTrialWeek"></div>
    </div>
</template>

<script>
import JSCharting from "jscharting-vue";
import { JSC } from "jscharting-vue";
import * as moment from "moment-timezone";
import "moment/locale/vi";

export default {
    name: "LineChartForDashboardShopingmall",
    props: {
        maxNumber: Number,
        rawData: Array,
        year: Number,
        listDateRange: Array,
        listColorToUse: Array,
        textLabel: String
    },
    mounted() {
        if (localStorage.lang == "en") {
            this.listDayInWeekFull = [
                "MON",
                "TUE",
                "WED",
                "THU",
                "FRI",
                "SAT",
                "SUN",
            ]
        }
    },
    watch: {
        rawData(val) {
            var scale = {range: [0, 10]}
            // if (this.maxNumber != 0) {
            //     scale = { interval: parseInt(this.maxNumber/50) }
            // }
            var self = this
            setTimeout(() => {
                self.chartOptions = {
                    debug: false,
                    type: "line",
                    legend_visible: false,
                    // margin: { bottom: 5, right: 20 },
                    defaultAxis: {
                        line_width: 0,
                        alternateGridFill: "none",
                        defaultTick_gridLine_color: "#D9D9D9"
                    },
                    yAxis: {
                        scale: {range: [0, this.maxNumber + 20]}, 
                        defaultTick: {
                            label: {
                                style: {
                                    fontSize: 14,
                                    fontFamily: "Roboto",
                                    color: "#5C6066",
                                    align: "left"
                                }
                            },
                            gridLine: { 
                                width: 1,
                                color: "#D9D9D9" 
                            },
                        }  
                    },
                    xAxis: {
                        crosshair_enabled: true,
                        scale_type: "time",
                        defaultTick: {
                            label_text: self.labelFormatter,
                            label: {
                                style: {
                                    fontSize: 14,
                                    fontFamily: "Roboto",
                                    color: "#5C6066",
                                    align: "center"
                                }
                            },
                            gridLine: { 
                                width: 1,
                                color: "#D9D9D9" 
                            },
                        },
                        scale: {
                            minorInterval: {
                                unit: "day",
                                multiplier: 1
                            }
                        },
                        
                    },
                    defaultTooltip: {
                        padding: 15,
                        label_text: self.tooltipFormatter
                    },
                    toolbar_visible: false,
                    defaultSeries: {
                        color: self.dataColor
                    },
                    series: val
                };
            }, 600)
        },
    },
    components: {
        JSCharting
    },
    data() {
        var self = this;
        return {
            listDayInWeek: ["T2 ", "T3 ", "T4 ", "T5 ", "T6 ", "T7 ", "CN "],
            listDayInWeekFull: [
                self.$t("dashboard.Monday"),
                self.$t("dashboard.Tuesday"),
                self.$t("dashboard.Wednesday"),
                self.$t("dashboard.Thursday"),
                self.$t("dashboard.Friday"),
                self.$t("dashboard.Saturday"),
                self.$t("dashboard.Sunday")
            ],
            month: 1,
            dataColor: "#8698fa",
            chartOptions: {
                debug: false,
                type: "line",
                legend_visible: false,
                margin: { bottom: 5, right: 20 },
                defaultAxis: {
                    line_width: 0,
                    alternateGridFill: "none",
                    defaultTick_gridLine_color: "#e5e5e5"
                },
                xAxis: {
                    crosshair_enabled: true,
                    scale_type: "time",
                    defaultTick: {
                        label_text: self.labelFormatter
                    },
                    scale: {
                        minorInterval: {
                            unit: "day",
                            multiplier: 1
                        }
                    },
                    minorInterval: {
                        unit: "day",
                        multiplier: 1
                    },
                },
                series: [
                    {
                        name: "previous",
                        defaultPoint: {
                            marker: {
                                outline: {
                                    width: 1,
                                    color: "white"
                                },
                                fill: "#004961",
                                type: "circle",
                                visible: true,
                                size: 12
                            }
                        },
                        line: {
                            color: "#004961",

                            // dashStyle: "dash",
                            width: 2
                        },
                        points: []
                    }
                ]
            },
            listColor: ["#004961", "#D81F41", "#68768A", "#00CC6A", "#004961", "#FFB900", "#0063B1", "#B146C2", "#0099BC"],
        };
    },
    methods: {
        labelFormatter(timestampFordate) {
            var weekDay = this.listDayInWeekFull[moment.unix(timestampFordate / 1000).day() - 1];
            if (moment.unix(timestampFordate / 1000).day() - 1 == -1) {
                weekDay = this.listDayInWeekFull[this.listDayInWeekFull.length-1]
            }
            
            return weekDay;
        },
        tooltipFormatter(pts) {
            var self = this
            pts = JSC.sortBy(pts, "series._i");
            var timestamp = moment(pts[0].x).format("X")
            var day = moment.unix(timestamp).format("DD/MM")
            var weekDay = this.listDayInWeekFull[moment.unix(timestamp).day() - 1];
            if (moment.unix(timestamp).day() - 1 == -1) {
                weekDay = this.listDayInWeekFull[this.listDayInWeekFull.length-1]
            }
            var dayPrevious = moment.unix(timestamp - 86400*7).format("DD/MM")

            if (pts[1].y == 0) {
                var content = '<span style="align:left;color:#1F1F1F;font-family: Roboto;font-style: normal;font-weight: 500;font-size: 16px;line-height: 24px;">' + weekDay +
                    "</span><br/><br/>" +
                    '<span style="vertical-align: middle;font-family: Roboto;font-style: normal;font-size: 18px;line-height: 20px;color: #1F1F1F;font-weight: 400;">' + day + ' ' + self.$t("Heatmap.ComparedWith") + ' ' + dayPrevious.toLowerCase() + ': ' + '<span style="color: #10893E"">0%</span></span><br/>' +
                    '<span style="vertical-align: middle;font-size: 24px;color: #D81F41;margin-bottom:16px; display:block;font-weight: 900"> ― <span style="vertical-align: middle;font-family: Roboto;font-style: normal;font-size: 18px;line-height: 20px;color: #1F1F1F;font-weight: 400;">' + day + ': ' + pts[0].y + '</span> </span><br/>'+
                    '<span style="vertical-align: middle;font-size: 24px;color: #004961;font-weight: 900;"> ― <span style="vertical-align: middle;font-family: Roboto;font-style: normal;font-size: 18px;line-height: 20px;color: #1F1F1F;font-weight: 400;">' + dayPrevious + ': ' + pts[1].y + '</span> </span>';
                return content;
            }
            if (pts[0].y == 0 && pts[1].y != 0)  {
                var content = '<span style="align:left;color:#1F1F1F;font-family: Roboto;font-style: normal;font-weight: 500;font-size: 16px;line-height: 24px;">' + weekDay +
                    "</span><br/><br/>" +
                    '<span style="vertical-align: middle;font-family: Roboto;font-style: normal;font-size: 18px;line-height: 20px;color: #1F1F1F;font-weight: 400;">' + day + ' ' + self.$t("Heatmap.ComparedWith") + ' ' + dayPrevious.toLowerCase() + ': ' + '<span style="color: #D81F41"">-100%</span></span><br/>' +
                    '<span style="vertical-align: middle;font-size: 24px;color: #D81F41;margin-bottom:16px; display:block;font-weight: 900"> ― <span style="vertical-align: middle;font-family: Roboto;font-style: normal;font-size: 18px;line-height: 20px;color: #1F1F1F;font-weight: 400;">' + day + ': ' + pts[0].y + '</span> </span><br/>'+
                    '<span style="vertical-align: middle;font-size: 24px;color: #004961;font-weight: 900;"> ― <span style="vertical-align: middle;font-family: Roboto;font-style: normal;font-size: 18px;line-height: 20px;color: #1F1F1F;font-weight: 400;">' + dayPrevious + ': ' + pts[1].y + '</span> </span>'
                return content;
            } else if (pts[0].y == 0 && pts[1].y == 0) {
                var content = '<span style="align:left;color:#1F1F1F;font-family: Roboto;font-style: normal;font-weight: 500;font-size: 16px;line-height: 24px;">' + weekDay +
                    "</span><br/><br/>" +
                    '<span style="vertical-align: middle;font-family: Roboto;font-style: normal;font-size: 18px;line-height: 20px;color: #1F1F1F;font-weight: 400;">' + day + ' ' + self.$t("Heatmap.ComparedWith") + ' ' + dayPrevious.toLowerCase() + ': ' + '<span style="color: #10893E"">0%</span></span><br/>' +
                    '<span style="vertical-align: middle;font-size: 24px;color: #D81F41;margin-bottom:16px; display:block;font-weight: 900"> ― <span style="vertical-align: middle;font-family: Roboto;font-style: normal;font-size: 18px;line-height: 20px;color: #1F1F1F;font-weight: 400;">' + day + ': ' + pts[0].y + '</span> </span><br/>'+
                    '<span style="vertical-align: middle;font-size: 24px;color: #004961;font-weight: 900;"> ― <span style="vertical-align: middle;font-family: Roboto;font-style: normal;font-size: 18px;line-height: 20px;color: #1F1F1F;font-weight: 400;">' + dayPrevious + ': ' + pts[1].y + '</span> </span>';
                return content;
            }
            var percentage = Math.ceil(((pts[0].y - pts[1].y) / pts[1].y) * 100)
            if (percentage < 0) {
                
                return (
                    '<span style="align:left;color:#1F1F1F;font-family: Roboto;font-style: normal;font-weight: 500;font-size: 16px;line-height: 24px;">' + weekDay +
                    "</span><br/><br/>" +
                    '<span style="vertical-align: middle;font-family: Roboto;font-style: normal;font-size: 18px;line-height: 20px;color: #1F1F1F;font-weight: 400;">' + day + ' ' + self.$t("Heatmap.ComparedWith") + ' ' + dayPrevious.toLowerCase() + ': ' + '<span style="color: #D81F41"">' + percentage +'%</span></span><br/>' +
                    '<span style="vertical-align: middle;font-size: 24px;color: #D81F41;margin-bottom:16px; display:block;font-weight: 900"> ― <span style="vertical-align: middle;font-family: Roboto;font-style: normal;font-size: 18px;line-height: 20px;color: #1F1F1F;font-weight: 400;">' + day + ': ' + pts[0].y + '</span> </span><br/>'+
                    '<span style="vertical-align: middle;font-size: 24px;color: #004961;font-weight: 900;"> ― <span style="vertical-align: middle;font-family: Roboto;font-style: normal;font-size: 18px;line-height: 20px;color: #1F1F1F;font-weight: 400;">' + dayPrevious + ': ' + pts[1].y + '</span> </span>'
                );
            } else {
                return (
                    '<span style="align:left;color:#1F1F1F;font-family: Roboto;font-style: normal;font-weight: 500;font-size: 16px;line-height: 24px;">' + weekDay +
                    "</span><br/><br/>" +
                    '<span style="vertical-align: middle;font-family: Roboto;font-style: normal;font-size: 18px;line-height: 20px;color: #1F1F1F;font-weight: 400;">' + day + ' ' + self.$t("Heatmap.ComparedWith") + ' ' + dayPrevious.toLowerCase() + ': ' + '<span style="color: #10893E"">' + percentage +'%</span></span><br/>' +
                    '<span style="vertical-align: middle;font-size: 24px;color: #D81F41;margin-bottom:16px; display:block;font-weight: 900"> ― <span style="vertical-align: middle;font-family: Roboto;font-style: normal;font-size: 18px;line-height: 20px;color: #1F1F1F;font-weight: 400;">' + day + ': ' + pts[0].y + '</span> </span><br/>'+
                    '<span style="vertical-align: middle;font-size: 24px;color: #004961;font-weight: 900;"> ― <span style="vertical-align: middle;font-family: Roboto;font-style: normal;font-size: 18px;line-height: 20px;color: #1F1F1F;font-weight: 400;">' + dayPrevious + ': ' + pts[1].y + '</span> </span>'
                );
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.chartWeek {
    .HiddenTrialWeek {
        width: 35px;
        height: 50px;
        background-color: #FFFFFF;
        position: absolute;
        z-index: 2;
        margin-top:-26.8px
    }
}
.columnChart {
    height: 370px;
}
</style>
