<template>
    <div style="width: 105%;" class="chartDay">
        <JSCharting :options="chartOptions" class="columnChart"></JSCharting>
        <div class="HiddenTrial"></div>
    </div>
</template>

<script>
import JSCharting from "jscharting-vue";
import { JSC } from "jscharting-vue";
import * as moment from "moment-timezone";
import "moment/locale/vi";

export default {
    props: {
        maxNumber: Number,
        rawData: Array,
        listColorToUse: Array
    },
    components: {
        JSCharting
    },
    watch: {
        rawData(val) {
            let scale = {range: [0, 10]}
            if (this.maxNumber != 0) {
                scale = {range: [0, this.maxNumber]}
            }
            let self = this
            self.chartOptions = {
                debug: false,
                type: "line",
                legend_visible: false,
                defaultAxis: {
                    line_width: 0,
                    alternateGridFill: "none",
                    defaultTick_gridLine_color: "#D9D9D9"
                },
                yAxis: { 
                    scale: scale,
                    defaultTick: {
                        label: {
                            style: {
                                fontSize: 14,
                                fontFamily: "Roboto",
                                color: "#5C6066",
                                align: "left"
                            }
                        },
                        gridLine: { 
                            width: 1,
                            color: "#D9D9D9" 
                        },
                    } 
                    
                },
                xAxis: {
                    crosshair_enabled: true,
                    scale_type: "time",
                    defaultTick: {
                        label_text: self.labelFormatter,
                        label: {
                            style: {
                                fontSize: 14,
                                fontFamily: "Roboto",
                                color: "#5C6066",
                                align: "center"
                            }
                        },
                        gridLine: { 
                            width: 1,
                            color: "#D9D9D9" 
                        },
                    },
                },
                defaultTooltip: {
                    padding: 15,
                    label_text: self.tooltipFormatter
                },
                toolbar_visible: false,
                defaultSeries: {
                    color: self.dataColor
                },
                series: val
            };
        },
    },
    data() {
        let self = this;
        return {
            dataColor: "#8698fa",
            dateArray: [
                "11/01",
                "12/01",
                "13/01",
                "14/01",
                "15/01",
                "16/01",
                "17/01",
                "18/01",
                "19/01",
                "20/01",
                "21/01",
                "22/01",
                "23/01",
                "24/01",
                "25/01",
                "26/01",
                "27/01",
            ],
            hourArray: [
                "7h - 8h",
                "8h - 9h",
                "9h - 10h",
                "10h - 11h",
                "11h - 12h",
                "12h - 13h",
                "13h - 14h",
                "14h - 15h",
                "15h - 16h",
                "16h - 17h",
                "17h - 18h",
                "18h - 19h",
                "19h - 20h",
                "20h - 21h",
                "21h - 22h",
                "22h - 23h",
                "23h - 24h",
            ],
            chartOptions: {
                debug: false,
                type: "line",
                legend_visible: false,
                margin: { bottom: 5, right: 20 },
                defaultAxis: {
                    line_width: 0,
                    alternateGridFill: "none",
                    defaultTick_gridLine_color: "#e5e5e5"
                },
                xAxis: {
                    crosshair_enabled: true,
                    scale_type: "time",
                    defaultTick: {
                        label_text: self.labelFormatter
                    },
                    scale: {
                        minorInterval: {
                            unit: "day",
                            multiplier: 1
                        }
                    },
                    minorInterval: {
                        unit: "day",
                        multiplier: 1
                    }
                },
                series: [
                    {
                        name: "previous",
                        defaultPoint: {
                            marker: {
                                outline: {
                                    width: 5,
                                    color: "white"
                                },
                                fill: "#004961",
                                type: "circle",
                                visible: true,
                                size: 12
                            }
                        },
                        line: {
                            color: "#004961",

                            // dashStyle: "dash",
                            width: 1
                        },
                        points: [
                        ]
                    },
                    {
                        name: "now",
                        defaultPoint: {
                            marker: {
                                outline: {
                                    width: 5,
                                    color: "white"
                                },
                                fill: "#D81F41",
                                type: "circle",
                                visible: true,
                                size: 12
                            }
                        },
                        line: {
                            color: "#D81F41"
                        },
                        points: [
                        ]
                    }
                ]
            },
            yesterday: "",
            todayString: ""
        };
    },
    methods: {
        labelFormatter(timestampFordate) {
            let hourRange = this.dateArray.indexOf(moment.unix(timestampFordate/1000).format("DD/MM"))
            // if (moment.unix(timestampFordate / 1000).hour() === 0) {
            //     return this.hourArray[hourRange];
            // }
            return this.hourArray[hourRange];
        },
        //ve tooltip o day
        tooltipFormatter(pts) {
            let self = this
            pts = JSC.sortBy(pts, "series._i");
            let timestamp = moment(pts[0].x).format("X")
            let hour = this.dateArray.indexOf(moment.unix(timestamp).format("DD/MM"))

            if (pts[0].y == 0 && pts[1].y != 0) {
                let content = '<span style="align:left;color:#1F1F1F;font-family: Roboto;font-style: normal;font-weight: 500;font-size: 16px;line-height: 24px;">' + self.hourArray[hour] +
                    "</span><br/><br/>" +
                    '<span style="vertical-align: middle;font-family: Roboto;font-style: normal;font-size: 18px;line-height: 20px;color: #1F1F1F;font-weight: 400;">' + self.$t("Report.Today") + ' ' + self.$t("Heatmap.ComparedWith") + ' ' + self.$t("Report.Yesterday").toLowerCase() + ': ' + '<span style="color: #D81F41"">-100%</span></span><br/>' +
                    '<span style="vertical-align: middle;font-size: 24px;color: #D81F41;margin-bottom:16px; display:block;font-weight: 900"> ― <span style="vertical-align: middle;font-family: Roboto;font-style: normal;font-size: 18px;line-height: 20px;color: #1F1F1F;font-weight: 400;">' + self.$t("Report.Today") + ': ' + pts[0].y + '</span> </span><br/>'+
                    '<span style="vertical-align: middle;font-size: 24px;color: #004961;font-weight: 900;"> ― <span style="vertical-align: middle;font-family: Roboto;font-style: normal;font-size: 18px;line-height: 20px;color: #1F1F1F;font-weight: 400;">' + self.$t("Report.Yesterday") + ': ' + pts[1].y + '</span> </span>';
                return content;
            }
            if (pts[1].y == 0) {
                let content = '<span style="align:left;color:#1F1F1F;font-family: Roboto;font-style: normal;font-weight: 500;font-size: 16px;line-height: 24px;">' + self.hourArray[hour] +
                    "</span><br/><br/>" +
                    '<span style="vertical-align: middle;font-family: Roboto;font-style: normal;font-size: 18px;line-height: 20px;color: #1F1F1F;font-weight: 400;">' + self.$t("Report.Today") + ' ' + self.$t("Heatmap.ComparedWith") + ' ' + self.$t("Report.Yesterday").toLowerCase() + ': ' + '<span style="color: #10893E"">0%</span></span><br/>' +
                    '<span style="vertical-align: middle;font-size: 24px;color: #D81F41;margin-bottom:16px; display:block;font-weight: 900"> ― <span style="vertical-align: middle;font-family: Roboto;font-style: normal;font-size: 18px;line-height: 20px;color: #1F1F1F;font-weight: 400;">' + self.$t("Report.Today") + ': ' + pts[0].y + '</span> </span><br/>'+
                    '<span style="vertical-align: middle;font-size: 24px;color: #004961;font-weight: 900;"> ― <span style="vertical-align: middle;font-family: Roboto;font-style: normal;font-size: 18px;line-height: 20px;color: #1F1F1F;font-weight: 400;">' + self.$t("Report.Yesterday") + ': ' + pts[1].y + '</span> </span>'
                return content;
            }
            let percentage = Math.ceil(((pts[0].y - pts[1].y) / pts[1].y) * 100)
            if (percentage < 0) {
                return (
                    '<span style="align:left;color:#1F1F1F;font-family: Roboto;font-style: normal;font-weight: 500;font-size: 16px;line-height: 24px;">' + self.hourArray[hour] +
                    "</span><br/><br/>" +
                    '<span style="vertical-align: middle;font-family: Roboto;font-style: normal;font-size: 18px;line-height: 20px;color: #1F1F1F;font-weight: 400;">' + self.$t("Report.Today") + ' ' + self.$t("Heatmap.ComparedWith") + ' ' + self.$t("Report.Yesterday").toLowerCase() + ': ' + '<span style="color: #D81F41"">' + percentage +'%</span></span><br/>' +
                    '<span style="vertical-align: middle;font-size: 24px;color: #D81F41;margin-bottom:16px; display:block;font-weight: 900"> ― <span style="vertical-align: middle;font-family: Roboto;font-style: normal;font-size: 18px;line-height: 20px;color: #1F1F1F;font-weight: 400;">' + self.$t("Report.Today") + ': ' + pts[0].y + '</span> </span><br/>'+
                    '<span style="vertical-align: middle;font-size: 24px;color: #004961;font-weight: 900;"> ― <span style="vertical-align: middle;font-family: Roboto;font-style: normal;font-size: 18px;line-height: 20px;color: #1F1F1F;font-weight: 400;">' + self.$t("Report.Yesterday") + ': ' + pts[1].y + '</span> </span>'
                );
            } else {
                return (
                    '<span style="align:left;color:#1F1F1F;font-family: Roboto;font-style: normal;font-weight: 500;font-size: 16px;line-height: 24px;">' + self.hourArray[hour] +
                    "</span><br/><br/>" +
                    '<span style="vertical-align: middle;font-family: Roboto;font-style: normal;font-size: 18px;line-height: 20px;color: #1F1F1F;font-weight: 400;">' + self.$t("Report.Today") + ' ' + self.$t("Heatmap.ComparedWith") + ' ' + self.$t("Report.Yesterday").toLowerCase() + ': ' + '<span style="color: #10893E"">' + percentage +'%</span></span><br/>' +
                    '<span style="vertical-align: middle;font-size: 24px;color: #D81F41;margin-bottom:16px; display:block;font-weight: 900"> ― <span style="vertical-align: middle;font-family: Roboto;font-style: normal;font-size: 18px;line-height: 20px;color: #1F1F1F;font-weight: 400;">' + self.$t("Report.Today") + ': ' + pts[0].y + '</span> </span><br/>'+
                    '<span style="vertical-align: middle;font-size: 24px;color: #004961;font-weight: 900;"> ― <span style="vertical-align: middle;font-family: Roboto;font-style: normal;font-size: 18px;line-height: 20px;color: #1F1F1F;font-weight: 400;">' + self.$t("Report.Yesterday") + ': ' + pts[1].y + '</span> </span>'
                );
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.chartDay {
    .HiddenTrial {
        width: 35px;
        height: 50px;
        background-color: #FFFFFF;
        position: absolute;
        z-index: 2;
        margin-top:-40.8px
    }
}
.columnChart {
    height: 410px;
}
</style>
