<template>
    <div style="width: 100%">
        <JSCharting :mutable="false" :options="chartOptions" class="columnChart"></JSCharting>
        <div class=""></div>
    </div>
</template>

<script>
import JSCharting from "jscharting-vue";
import { JSC } from "jscharting-vue";
import * as moment from "moment-timezone";
import "moment/locale/vi";
import { Api } from "@/api/index";

const trendingDashboardShopingMall = Api.get("trendingDashboardShopingMall");

export default {
    mounted() {
        this.getData()
    },
    name: "LineChartForDashboardShopingmall",
    components: {
        JSCharting
    },
    data() {
        let self = this;
        return {
            dataColor: "#8698fa",
            dateArray: [
                "11/01",
                "12/01",
                "13/01",
                "14/01",
                "15/01",
                "16/01",
                "17/01",
                "18/01",
                "19/01",
                "20/01",
                "21/01",
                "22/01",
                "23/01",
                "24/01",
                "25/01",
                "26/01",
                "27/01",
            ],
            hourArray: [
                "7h - 8h",
                "8h - 9h",
                "9h - 10h",
                "10h - 11h",
                "11h - 12h",
                "12h - 13h",
                "13h - 14h",
                "14h - 15h",
                "15h - 16h",
                "16h - 17h",
                "17h - 18h",
                "18h - 19h",
                "19h - 20h",
                "20h - 21h",
                "21h - 22h",
                "22h - 23h",
                "23h - 24h",
            ],
            chartOptions: {
                debug: false,
                type: "line",
                legend_visible: false,
                margin: { bottom: 5, right: 20 },
                defaultAxis: {
                    line_width: 0,
                    alternateGridFill: "none",
                    defaultTick_gridLine_color: "#e5e5e5"
                },
                xAxis: {
                    crosshair_enabled: true,
                    scale_type: "time",
                    defaultTick: {
                        label_text: self.labelFormatter
                    },
                    scale: {
                        minorInterval: {
                            unit: "day",
                            multiplier: 1
                        }
                    },
                    minorInterval: {
                        unit: "day",
                        multiplier: 1
                    }
                },
                defaultTooltip: {
                    padding: 15,
                    label_text: self.tooltipFormatter
                },
                toolbar_visible: false,
                defaultSeries: {
                    color: self.dataColor
                },
                series: [
                    {
                        name: "previous",
                        defaultPoint: {
                            marker: {
                                outline: {
                                    width: 5,
                                    color: "white"
                                },
                                fill: "#004961",
                                type: "circle",
                                visible: true,
                                size: 12
                            }
                        },
                        line: {
                            color: "#004961",

                            // dashStyle: "dash",
                            width: 1
                        },
                        points: [
                        ]
                    },
                    {
                        name: "now",
                        defaultPoint: {
                            marker: {
                                outline: {
                                    width: 5,
                                    color: "white"
                                },
                                fill: "#D81F41",
                                type: "circle",
                                visible: true,
                                size: 12
                            }
                        },
                        line: {
                            color: "#D81F41"
                        },
                        points: [
                        ]
                    }
                ]
            },
        };
    },
    methods: {
        getData() {
            let presentData = [];
            let preivousData = [];
            let self = this;
            let hour = [
                "1/11/2020",
                "1/12/2020",
                "1/13/2020",
                "1/14/2020",
                "1/15/2020",
                "1/16/2020",
                "1/17/2020",
                "1/18/2020",
                "1/19/2020",
                "1/20/2020",
                "1/21/2020",
                "1/22/2020",
                "1/23/2020",
                "1/24/2020",
                "1/25/2020",
                "1/26/2020",
                "1/27/2020"
            ];

            let dateStart = moment()
                .startOf("day")
                .unix() - 86400;

            trendingDashboardShopingMall
                .get(dateStart, dateStart)
                .then(response => {
                    let maxNumber = 0
                    for (let i = 0; i < hour.length; i++) {
                        let presentDataHour = [
                            hour[i],
                            response.data.statisticToday[i]
                        ];
                        presentData.push(presentDataHour);
                        if (maxNumber < response.data.statisticToday[i]) {
                            maxNumber = response.data.statisticToday[i]
                        }
                        let preivousDataHour = [
                            hour[i],
                            response.data.statisticPrevious[i]
                        ];
                        preivousData.push(preivousDataHour);
                        if (maxNumber < response.data.statisticPrevious[i]) {
                            maxNumber = response.data.statisticPrevious[i]
                        }
                    }
                    let scale = {range: [0, 10]}
                    if (maxNumber < 100) {
                        this.maxNumber = this.round5(maxNumber)
                    } else if (maxNumber >= 100 && maxNumber < 500) {
                        this.maxNumber = this.round100(maxNumber)
                    } else if (maxNumber >= 500) {
                        this.maxNumber = this.round500(maxNumber)
                    }
                    if (this.maxNumber != 0) {
                        scale = {range: { min: 0, max: this.maxNumber + this.maxNumber/4}, interval: this.maxNumber / 5}
                    }
                    this.chartOptions = {
                        debug: false,
                        type: 'line',
                        legend_visible: false,
                        defaultAxis: {
                            line_width: 0,
                            alternateGridFill: 'none',
                            defaultTick_gridLine_color: '#e5e5e5'
                        },
                        yAxis: { 
                            scale: scale, 
                        },
                        xAxis: {
                            crosshair_enabled: true,
                            scale_type: 'time',
                            defaultTick: {
                                label_text: self.labelFormatter
                            },
                            scale: {
                                minorInterval: {
                                    unit: "day",
                                    multiplier: 1
                                }
                            },
                            minorInterval: {
                                unit: "day",
                                multiplier: 1
                            }
                        },
                        defaultTooltip: {
                            padding: 15,
                            label_text: self.tooltipFormatter
                        },
                        toolbar_visible: false,
                        defaultSeries: {
                            color: self.dataColor,
                        },
                        series: [
                            {
                                name: 'previous',
                                defaultPoint: {
                                    marker: {
                                        outline: {
                                            width: 1,
                                            color: 'white'
                                        },
                                        fill: '#004961',
                                        type: 'circle',
                                        visible: true,
                                        size: 12
                                    }
                                },
                                line: {
                                    color: "#004961",
                                    dashStyle: "dash",
                                    width: 2,

                                },
                                points: preivousData
                            },
                            {
                                name: 'now',
                                defaultPoint: {
                                    marker: {
                                        outline: {
                                            width: 1,
                                            color: 'white'
                                        },
                                        fill: '#D81F41',
                                        type: 'circle',
                                        visible: true,
                                        size: 12
                                    }
                                },
                                line: {
                                    color: "#D81F41",
                                    width: 2,
                                },
                                points: presentData
                            }
                        ]
                    }
                });
        },
        labelFormatter(timestampFordate) {
            let hourRange = this.dateArray.indexOf(moment.unix(timestampFordate/1000).format("DD/MM"))
            if (moment.unix(timestampFordate / 1000).hour() === 0) {
                return this.hourArray[hourRange];
            }
            return "";
        },
        //ve tooltip o day
        tooltipFormatter(pts) {
            let self = this
            pts = JSC.sortBy(pts, "series._i");
            let timestamp = moment(pts[0].x).format("X")
            let hour = this.dateArray.indexOf(moment.unix(timestamp).format("DD/MM"))

            
            if (pts[0].y == 0) {
                let content = '<span style="align:left;color:#1F1F1F;font-family: Roboto;font-style: normal;font-weight: 500;font-size: 16px;line-height: 24px;">' + self.hourArray[hour] +
                    "</span><br/><br/>" +
                    '<span style="vertical-align: middle;font-family: Roboto;font-style: normal;font-size: 18px;line-height: 20px;color: #1F1F1F;font-weight: 400;">' + self.$t("Report.Today") + ' ' + self.$t("Heatmap.ComparedWith") + ' ' + self.$t("Report.Yesterday").toLowerCase() + ': ' + '<span style="color: #10893E"">0%</span></span><br/>' +
                    '<span style="vertical-align: middle;font-size: 24px;color: #D81F41;margin-bottom:16px; display:block;font-weight: 900"> ― <span style="vertical-align: middle;font-family: Roboto;font-style: normal;font-size: 18px;line-height: 20px;color: #1F1F1F;font-weight: 400;">' + self.$t("Report.Today") + ': ' + pts[1].y + '</span> </span><br/>'+
                    '<span style="vertical-align: middle;font-size: 24px;color: #004961;font-weight: 900;"> ― <span style="vertical-align: middle;font-family: Roboto;font-style: normal;font-size: 18px;line-height: 20px;color: #1F1F1F;font-weight: 400;">' + self.$t("Report.Yesterday") + ': ' + pts[0].y + '</span> </span>';
                return content;
            }
            if (pts[1].y == 0) {
                let content = '<span style="align:left;color:#1F1F1F;font-family: Roboto;font-style: normal;font-weight: 500;font-size: 16px;line-height: 24px;">' + self.hourArray[hour] +
                    "</span><br/><br/>" +
                    '<span style="vertical-align: middle;font-family: Roboto;font-style: normal;font-size: 18px;line-height: 20px;color: #1F1F1F;font-weight: 400;">' + self.$t("Report.Today") + ' ' + self.$t("Heatmap.ComparedWith") + ' ' + self.$t("Report.Yesterday").toLowerCase() + ': ' + '<span style="color: #D81F41"">-100%</span></span><br/>' +
                    '<span style="vertical-align: middle;font-size: 24px;color: #D81F41;margin-bottom:16px; display:block;font-weight: 900"> ― <span style="vertical-align: middle;font-family: Roboto;font-style: normal;font-size: 18px;line-height: 20px;color: #1F1F1F;font-weight: 400;">' + self.$t("Report.Today") + ': ' + pts[1].y + '</span> </span><br/>'+
                    '<span style="vertical-align: middle;font-size: 24px;color: #004961;font-weight: 900;"> ― <span style="vertical-align: middle;font-family: Roboto;font-style: normal;font-size: 18px;line-height: 20px;color: #1F1F1F;font-weight: 400;">' + self.$t("Report.Yesterday") + ': ' + pts[0].y + '</span> </span>'
                return content;
            }
            let percentage = Math.ceil(((pts[1].y - pts[0].y) / pts[0].y) * 100)
            if (percentage < 0) {
                
                return (
                    '<span style="align:left;color:#1F1F1F;font-family: Roboto;font-style: normal;font-weight: 500;font-size: 16px;line-height: 24px;">' + self.hourArray[hour] +
                    "</span><br/><br/>" +
                    '<span style="vertical-align: middle;font-family: Roboto;font-style: normal;font-size: 18px;line-height: 20px;color: #1F1F1F;font-weight: 400;">' + self.$t("Report.Today") + ' ' + self.$t("Heatmap.ComparedWith") + ' ' + self.$t("Report.Yesterday").toLowerCase() + ': ' + '<span style="color: #D81F41"">' + percentage +'%</span></span><br/>' +
                    '<span style="vertical-align: middle;font-size: 24px;color: #D81F41;margin-bottom:16px; display:block;font-weight: 900"> ― <span style="vertical-align: middle;font-family: Roboto;font-style: normal;font-size: 18px;line-height: 20px;color: #1F1F1F;font-weight: 400;">' + self.$t("Report.Today") + ': ' + pts[1].y + '</span> </span><br/>'+
                    '<span style="vertical-align: middle;font-size: 24px;color: #004961;font-weight: 900;"> ― <span style="vertical-align: middle;font-family: Roboto;font-style: normal;font-size: 18px;line-height: 20px;color: #1F1F1F;font-weight: 400;">' + self.$t("Report.Yesterday") + ': ' + pts[0].y + '</span> </span>'
                );
            } else {
                return (
                    '<span style="align:left;color:#1F1F1F;font-family: Roboto;font-style: normal;font-weight: 500;font-size: 16px;line-height: 24px;">' + self.hourArray[hour] +
                    "</span><br/><br/>" +
                    '<span style="vertical-align: middle;font-family: Roboto;font-style: normal;font-size: 18px;line-height: 20px;color: #1F1F1F;font-weight: 400;">' + self.$t("Report.Today") + ' ' + self.$t("Heatmap.ComparedWith") + ' ' + self.$t("Report.Yesterday").toLowerCase() + ': ' + '<span style="color: #10893E"">' + percentage +'%</span></span><br/>' +
                    '<span style="vertical-align: middle;font-size: 24px;color: #D81F41;margin-bottom:16px; display:block;font-weight: 900"> ― <span style="vertical-align: middle;font-family: Roboto;font-style: normal;font-size: 18px;line-height: 20px;color: #1F1F1F;font-weight: 400;">' + self.$t("Report.Today") + ': ' + pts[1].y + '</span> </span><br/>'+
                    '<span style="vertical-align: middle;font-size: 24px;color: #004961;font-weight: 900;"> ― <span style="vertical-align: middle;font-family: Roboto;font-style: normal;font-size: 18px;line-height: 20px;color: #1F1F1F;font-weight: 400;">' + self.$t("Report.Yesterday") + ': ' + pts[0].y + '</span> </span>'
                );
            }
            
        },
        round5(x) {
            return Math.ceil(x / 5) * 5;
        },
        round100(x) {
            return Math.ceil(x / 100) * 100;
        },
        round500(x) {
            return Math.ceil(x / 500) * 500;
        },
    }
};
</script>

<style lang="scss" scoped>
.columnChart {
    height: 400px;
}
</style>
