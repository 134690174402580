<template>
    <div id="chart" class="CustomerAge">
        <apexchart type="bar" height="250" :options="chartOptions" :series="seriesForChart"></apexchart>
      </div>
</template>
<script>
export default {
    name: "BarAge",
    props: {
          series: {
              type: Array
          },
    },
    watch: {
        series(val) {
            this.seriesForChart = val
            let self = this
            let total = val[0].data.map(i=>Number(i)).reduce((one, two) => one + two, 0)
            this.chartOptions = {
                chart: {
                    type: 'bar',
                    height: 250
                },
                colors: ["#0099BC"],
                plotOptions: {
                    bar: {
                        borderRadius: 4,
                        horizontal: true,
                    }
                },
                dataLabels: {
                    enabled: false
                },
                xaxis: {
                    categories: ['>60', '46-60', '35-45', '25-34', '17-24', '10-16'],
                    labels: {
                        rotate: 0,
                        show: false,
                        maxHeight: 120,
                        fontFamily: "Roboto",
                        fontSize: '14px',
                    },
                },
                tooltip: {
                    enabled: true,
                    marker: {
                        show: false,
                    },
                    y: {
                        formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {
                            return ""
                        },
                        title: {
                            formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {
                                return self.$t("CustomerReport.Total") + ": " + "<b>" + parseInt(self.series[seriesIndex]["data"][dataPointIndex]) +"</b>" + "<br>" + self.$t("CustomerReport.Percent") + ": " + "<b>" +(((parseInt(self.series[seriesIndex]["data"][dataPointIndex]) / total) * 100)).toFixed(2) + " %" + "</b>"
                            },
                            style: {
                                fontSize:  '14px',
                                fontFamily: "Roboto",
                                color:  '#000000'
                            },
                        },
                        labels: {
                            fontFamily: "Roboto",
                            fontSize: '14px',
                        },
                    },
                }
            }
        }
    },
    data() {
        return {
            seriesForChart: [
                {
                    data: [0, 0, 0, 0, 0, 0]
                }  
            ],
            chartOptions: {
                
            },
        };
    }
};
</script>
<style lang="scss">
.CustomerAge {
    .apexcharts-tooltip-title {
        background: #0063B1 !important;
        color: #FFFFFF !important;
        border-radius-topleft: 5px;
        border-radius-topright: 5px;
    }
    .apexcharts-legend-marker {
        position: relative;
        display: inline-block;
        cursor: pointer;
        margin-right: 3px;
        border-style: solid;
        margin-left: 16px;
    }
    .apexcharts-xaxis {
        display: none;
    }
}

</style>
